import { SpendsTypes } from "./actionsTypes";


export const getSpends = (next_page: string, filter: object) =>({
    type: SpendsTypes.GET_SPENDS,
    next_page,
    filter
})
export const getSpendsSuccess = (data : any) =>({
    type: SpendsTypes.GET_SPENDS_SUCCESS,
    payload: data,
})

export const clearSpends = () => ({
    type: SpendsTypes.CLEAR_SPENDS,
})

export const getSpend = (id: number) => ({
    type: SpendsTypes.GET_SPEND,
    id
  })

  export const getSpendSuccess = (data : any) => ({
    type: SpendsTypes.GET_SPEND_SUCCESS,
    payload: data,
  })

export const getSpendFail = (error : any) => ({
    type: SpendsTypes.SPEND_FAIL,
    payload: error,
})








export const clearSpend = () => ({
    type: SpendsTypes.CLEAR_SPEND,
})

export const modalSpend = (status: boolean) => ({ 
    type: SpendsTypes.MODAL_SPEND,
    status
})

export const addSpend =(data: any) =>({
    type: SpendsTypes.ADD_SPEND,
    data
})

export const addSpendSuccess = (data: any) => ({
    type: SpendsTypes.ADD_SPEND_SUCCESS,
    payload: data
  })

  export const addSpendFail = (error : any) => ({
    type: SpendsTypes.SPEND_FAIL,
    payload: error,
  })


  export const updSpend = (data : any, id: number) => ({
    type: SpendsTypes.UPD_SPEND,
    id, data
  })
  
  export const updSpendSuccess = (data: any) => ({
    type: SpendsTypes.UPD_SPEND_SUCCESS,
    payload: data
  })
  
  export const updSpendFail = (error : any) => ({
    type: SpendsTypes.SPEND_FAIL,
    payload: error,
  })



  export const delSpend = (id: number) => ({
    type: SpendsTypes.DEL_SPEND,
    id
  })
  
  export const delSpendSuccess = (id: number) => ({

    type: SpendsTypes.DEL_SPEND_SUCCESS,
    payload: id
  })
  
  export const delSpendFail = (error : any) => ({
    type: SpendsTypes.SPEND_FAIL,
    payload: error,
  })