import { PermissionsTypes } from "./actionTypes"

export const getPermissions = () => ({
    type: PermissionsTypes.GET_PERMISSIONS,
  })
  
  export const getPermissionsSuccess = (payload : any) => ({
    type: PermissionsTypes.GET_PERMISSIONS_SUCCESS,
    payload,
  })

export const getPermissionsFailure = (payload : any) => ({
    type: PermissionsTypes.GET_PERMISSIONS_FAILURE,
    payload
})

export const getPermission = (id: number) => ({
  type: PermissionsTypes.GET_PERMISSION,
  id
})

export const getPermissionSuccess = (payload : any) => ({
  type: PermissionsTypes.GET_PERMISSION_SUCCESS,
  payload,
})

export const getPermissionFailure = (payload : any) => ({
  type: PermissionsTypes.GET_PERMISSIONS_FAILURE,
  payload
})
export const addPermission = (data: any) => ({
  type: PermissionsTypes.ADD_PERMISSIONS,
  data
})

export const addPermissionSuccess = (data: any) => ({
  type: PermissionsTypes.ADD_PERMISSIONS_SUCCESS,
  payload: data
})

export const addPermissionFail = (error : any) => ({
  type: PermissionsTypes.PERMISSIONS_FAIL,
  payload: error,
})
export const updPermissions = (data : any, id: number) => ({
  type: PermissionsTypes.UPD_PERMISSIONS,
  id, data
})

export const updPermissionsSuccess = (data: any) => ({
  type: PermissionsTypes.UPD_PERMISSIONS_SUCCESS,
  payload: data
})

export const updPermissionsFail = (error : any) => ({
  type: PermissionsTypes.PERMISSIONS_FAIL,
  payload: error,
})

export const delPermissions = (id: number) => ({
  type: PermissionsTypes.DEL_PERMISSIONS,
  id
})

export const delPermissionsSuccess = (id: number) => ({
  type: PermissionsTypes.DEL_PERMISSIONS_SUCCESS,
  payload: id
})

export const delPermissionsFail = (error : any) => ({
  type: PermissionsTypes.PERMISSIONS_FAIL,
  payload: error,
})

export const modalPermissions = (status: boolean) => ({
  type: PermissionsTypes.PERMISSIONS_MODAL,
  status
})

export const clearPermission = () => ({
  type: PermissionsTypes.CLEAR_PERMISSION,
})