import {TrakerTypes, TrakerState} from "./actionTypes"

export const INIT_STATE : TrakerState = {
  data: {},
  isOpenModal: false,
  error: {},
  
}

const traker = (state = INIT_STATE, action :any) => {
  switch (action.type) {
    case TrakerTypes.GET_UPDATE_BROKER_NAME:
      return {
        ...state,
        loading: true
      }

      case TrakerTypes.GET_TRAKER:
        return {
          ...state,
          loading: true
        }

    case TrakerTypes.GET_TRAKER_SUCCESS:
      return {
        ...state,
        data: action.data,
      }
      case TrakerTypes.ADD_CAPA_SUCCESS:
      return {
            ...state,
            data: [...state.data, action.payload],
      }

      case TrakerTypes.MODAL_TRAKER:
        return {
            ...state,
            isOpenModal: action.status
        }

    default:
      return state
  }
}

export default traker