import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import { Col, Row } from "reactstrap";
import { CSVLink } from "react-csv";
import { getCheckin, modalCheckin } from "src/store/actions"
import { useDispatch, useSelector } from "react-redux";
import { string } from "yup";
import usePermissions from "src/hooks/usePermissions";
import Select from "src/components/Common/Select";
import { getDashboard } from "src/store/actions";


type Data = {
  // @ts-ignore
  account: string;
  [day: string]: {
    checkin_time: string;
    checkout_time: string;
    workTime: string;
    totalBreakTime: string;
    total_break: number
    color: string;
    sickDay: number;
    remoteDay:number;
    totalImportantTime: string;
    totalDinnerTime: string;
  };
};

const month = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" }
];


const selectType = [
  { value: "1", label: 'All-info' },
  { value: "2", label: "Start-work" },
  { value: "3", label: "Boolean" },
]

const TableCheck = (props: any) => {
  const { data, daysInMonth } = props;
  const [selectedMonth, setSelectedMonth] = useState('');
  const dispatch = useDispatch();
  const { hasPermission } = usePermissions();

  const { data: login } = useSelector((state: any) => state.login)
  const teamsAll = useSelector((state: any) => state.dashboard || { data: [] });
  const isAdmin = login.roles.find((item: any) => item.name !== "admin")
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);
  const selectTeams = [...teamsAll.data, { value: 0, label: "All" }]


  console.log(data)

  const findMatchingIds = () => {
    return data.filter((item: any) =>
      Object.values(item).some((value: any) =>
        value?.team?.id === selectedUser?.value
      )
    );
  };

  // const resultFilterData = findMatchingIds();

  const formatData = (data: Data[]) => {
    return data?.map((item: any) => {
      const formattedItem: Data = {
        account: item.account,
      };
      daysInMonth.forEach((day: keyof Data) => {
        const dayData = item[day];
        formattedItem[day] = {
          checkin_time: dayData.checkin_time.toString(),
          checkout_time: dayData.checkout_time.toString(),
          workTime: dayData.workTime.toString(),
          totalBreakTime: dayData.totalBreakTime.toString(),
          total_break: dayData.total_break,
          color: dayData.color.toString(),
          sickDay: dayData.sickDay.toString(),
          remoteDay: dayData.remoteDay.toString(),
          totalDinnerTime: dayData.remoteDay.toString(),
          totalImportantTime: dayData.remoteDay.toString(),
        };
      });
      return formattedItem;
    });
  };


  const csvData = useMemo(() => {
    const formattedData: any[] = [];
    const headerRow: any = {
      account: '',
    };

    formattedData.push(headerRow);

    const dataFilter = selectedUser?.label !== "All" ? findMatchingIds() : data

    dataFilter.forEach((item: Data) => {

      const formattedItem: any = {
        account: item.account,
      };

      

      daysInMonth.forEach((day: string) => {
        const dayData = item[day];
        // format date from to 00:00:00 to 00:00
        // const timeWithSeconds = "time-break-" + dayData.totalBreakTime; 
        const timeParts = dayData.totalBreakTime.split(':');
        const timeWithoutSeconds = timeParts[0] + ':' + timeParts[1];


        const getTypeCSVData = (type: any) => {
          switch (type) {
            case "1":
              return `start-${dayData.checkin_time}\nend-${dayData.checkout_time}\nbreak-${dayData.total_break}\ntime-break-${dayData.totalBreakTime}\nsick-day-${dayData.sickDay}\nremote-day-${dayData.remoteDay}\ndinner-time-${dayData.totalDinnerTime}\nimportant-time${dayData.totalImportantTime}`;
            case "2":
              return `start-${dayData.checkin_time}`;
            case "3":
              return !dayData.checkin_time ? "0" :"1";
            default:
              return `start-${dayData.checkin_time}\nend-${dayData.checkout_time}\nbreak-${dayData.total_break}\ntime-break-${timeWithoutSeconds}`;
          }
        }

        const csv = getTypeCSVData(selectedType?.value)

        formattedItem[day] = csv; 
      });
      formattedData.push(formattedItem);
    });
    return formattedData;
  }, [data, daysInMonth, selectedUser, selectedType]);

  const formattedData = useMemo(() => {
    const dataToFormat = selectedUser?.label !== "All" ? findMatchingIds() : data;
    return formatData(dataToFormat);
  }, [data, selectedUser]);

  const columns = useMemo(
    () => [
      {
        Header: 'Account',
        accessor: 'account',
        disableFilters: true,
      },
      ...daysInMonth.map((day: any) => ({
        Header: day,
        accessor: day,
        disableFilters: true,
        Cell: ({ value }: { value: Data[keyof Data] }) => (
          <div style={{ minWidth: '175px', width: 'auto' }}>
            <div style={{ color: value.color }}>
              At work: <span style={{ fontWeight: 'bold' }}>{value.checkin_time}</span>
            </div>
            {
              !isAdmin &&
              <>
                <div>Left work: {value.checkout_time}</div>
                <div>Total break: {value.total_break}</div>
                <div>Total break time: {value.totalBreakTime}</div>
                <div>Work Time: {value.workTime}</div>
                <div>Sick Day: {value.sickDay}</div>
                <div>Remote Day: {value.remoteDay}</div>
                <div>Dinner Time: {value.totalDinnerTime}</div>
                <div>Important Time: {value.totalImportantTime}</div>
              </>
            }
          </div>
        ),
      })),
    ],
    [daysInMonth]
  );

  const handleMonthChange = (event: any) => {
    dispatch(getCheckin({ month: event.target.value }))
    setSelectedMonth(event.target.value);
  };

  useEffect(() => {
    dispatch(getDashboard());
  }, [])

  return (
    <>
      <Row className="align-items-center">
        <Col md={6}>
          <div className="d-flex mb-3 w-25">
            <select id="month" value={selectedMonth} onChange={handleMonthChange} className="form-select">
              <option value="">Select month</option>
              {month.map((month, index) => (
                <option key={index} value={month.value}>{month.label}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col md={6} className="d-flex gap-2 justify-content-end">
          <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
            <Select
              isSearchable
              placeholder="Select Team"
              options={selectTeams}
              onChange={(selectedOption: any) =>
                setSelectedUser(selectedOption)
              }
              value={selectedUser}
            />
            <Select
              isSearchable
              placeholder="Select Teamplate"
              options={selectType}
              onChange={(selectedOption: any) =>
                setSelectedType(selectedOption)
              }
              value={selectedType}
            />
            <div>
              {
                hasPermission("download") &&
                <CSVLink data={csvData}>
                  <button className="btn btn-warning">DownLoad CSV</button>
                </CSVLink>
              }
            </div>
          </div>
        </Col>
      </Row>
      <TableContainer
        columns={columns}
        data={formattedData}
        isGlobalFilter={true}
        customPageSize={10}
        className="table align-middle datatable dt-responsive table-check nowrap"
        theadClass={{ width: "100%", }}
      />
    </>
  )
}

export default React.memo(TableCheck);