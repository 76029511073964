import React, { useMemo } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

const MySelect = (props: any) => {
    const theme = useSelector((state: any) => state.Layout.layoutMode);

    const colourStyles = useMemo(() => {
        return theme === "dark"
            ? {
                control: (styles: any) => ({ ...styles, backgroundColor: "#363a38", color: "white",  }),
                option: (
                    styles: any,
                    { isFocused, isSelected }: any
                ) => {
                    return {
                        ...styles,
                        backgroundColor: isSelected
                            ? "#189be7"
                            : isFocused
                                ? "#858d98"
                                : "#363a38",
                    };
                },
            }
            : {
                control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
                option: (
                    styles: any,
                    { isFocused, isSelected }: any
                ) => {
                    return {
                        ...styles,
                        backgroundColor: isSelected
                            ? "#189be7"
                            : isFocused
                                ? "#f2f2f2"
                                : "#ffffff",
                    };
                },
            };
    }, [theme]);

    return (
        <Select
            {...props}
            className="react-select-container"
            classNamePrefix="react-select"
            styles={colourStyles}

        />
    );
};

MySelect.displayName = 'MySelect'

export default MySelect;
