import { all, fork } from "redux-saga/effects"

import LayoutSaga from "./layout/saga";
import authSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import teamsSaga from "./team/saga";
import usersSaga from "./user/saga";
import brokerSaga from "./brokers/saga";
import checkinSaga from "./checkin/saga";
import spendsSaga from "./spends/saga";
import permissionsSaga from "./permissions/saga";
import rolesSaga from "./roles/saga";
import priceDaySaga from "./price_day/saga";
import statisticSaga from "./statistic/saga";
import dashboardSaga from "./dashboard/saga";
import OrdersSaga from "./orders/saga";
import TrakerSaga from "./tracker/saga"
import JiraSaga from "./jira/saga"



export default function* rootSaga() {
  yield all([
    //public
    fork(brokerSaga),
    fork(LayoutSaga),
    fork(authSaga),
    fork(ProfileSaga),
    fork(teamsSaga),
    fork(usersSaga),
    fork(checkinSaga),
    fork(spendsSaga),
    fork(permissionsSaga),
    fork(rolesSaga),
    fork(priceDaySaga),
    fork(statisticSaga),
    fork(dashboardSaga),
    fork(OrdersSaga),
    fork(TrakerSaga),
    fork(JiraSaga)
  ])
}
