import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import UserProfile from "src/pages/Authentication/user-profile";

import Teams from "../pages/Teams";
import Users from "../pages/Users";
import Checkin from "src/pages/Checkin";
import Spends from "src/pages/Spends";
import Permissions from "../pages/Permissions";
import Roles from "src/pages/Roles";
import NoAccess from "src/pages/NoAccess";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  { path: "/profile", component: <UserProfile /> },
  { path: "/spends", component: <Spends />},
  { path: "/teams", component: <Teams /> },
  { path: "/users", component: <Users /> },
  { path: "/check-in", component: <Checkin /> },
  { path: "/permissions", component: <Permissions /> },
  { path: "/roles", component: <Roles /> },

  { path: "/", exact: true, component: <Navigate to="/check-in" /> },

];

const authRoutes: Array<RouteProps> = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  {path: "/no-access", component: <NoAccess/>},

];

export { userRoutes, authRoutes };
