import {JiraTypes} from "./actionTypes"

export const getProjects = () => ({
  type: JiraTypes.GET_PROJECTS
})

export const getProjectsSuccess = (data : any) => {
  return {
    type: JiraTypes.GET_PROJECTS_SUCCESS,
    payload: data,
  }
}

export const clearProjects = () => ({
    type: JiraTypes.CLEAR_PROJECTS,
})

export const getJiraUsers = () => ({
  type: JiraTypes.GET_JIRA_USERS
})

export const getJiraUsersSuccess = (data : any) => {
  return {
    type: JiraTypes.GET_JIRA_USERS_SUCCESS,
    payload: data,
  }
}

export const clearJiraUsers = () => ({
    type: JiraTypes.CLEAR_JIRA_USERS,
})

export const getJiraStatistics = (data: any) => ({
  type: JiraTypes.GET_JIRA_STATISTICS,
  data
})

export const getJiraStatisticsSuccess = (data : any) => {
  return {
    type: JiraTypes.GET_JIRA_STATISTICS_SUCCESS,
    payload: data,
  }
}

export const clearJiraStatistics = () => ({
  type: JiraTypes.CLEAR_JIRA_STATISTICS,
})


export const jiraFail = (error : any) => ({
  type: JiraTypes.JIRA_FAIL,
  payload: error,
})