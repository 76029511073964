export enum SpendsTypes {
    GET_SPENDS = "@@spends/GET_SPENDS",
    GET_SPENDS_SUCCESS = "@@spends/GET_SPENDS_SUCCES",
    CLEAR_SPENDS = "@@spends/CLEAN_SPENDS",
    CLEAR_SPEND = "@@spends/CLEAR_SPEND",
    GET_SPEND = "@@spends/GET_SPEND",
    GET_SPEND_SUCCESS = "@@team/GET_SPEND_SUCCESS",
    ADD_SPEND = "@@spends/ADD_SPEND",
    ADD_SPEND_SUCCESS = "@@spends/ADD_SPEND_SUCCESS",
    UPD_SPEND = "@@spends/UPD_SPEND",
    UPD_SPEND_SUCCESS = "@@spends/UPD_SPEND_SUCCESS",
    DEL_SPEND = "@@spends/DEL_SPEND",
    DEL_SPEND_SUCCESS = "@@spends/DEL_SPEND_SUCCESS",

    SPEND_FAIL ="@@spend/SPEND_FAIL",
    MODAL_SPEND = "@@spend/MODAL_SPEND",
}

export interface SpendsState {
    data  : Array<any>;
    item: Object;
    pagination: Object;
    error : Object;
    loading: boolean,
    loaded: boolean,
    isOpenModal: boolean,
}