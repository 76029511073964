import React, {useEffect, useMemo, useState} from "react";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import {useDispatch} from "react-redux";
import {delUser, getUser} from "../../../store/user/actions";
import { clearTeams, getTeams } from "src/store/actions";
import {Badge} from "reactstrap";
import usePermissions from "src/hooks/usePermissions";

let filter = {'per_page': 5};

const TableTeam = React.memo((props: any) =>  {

    const { data, pageSize, } = props
    const dispatch = useDispatch();
    const {hasPermission} = usePermissions();

    const [deleteModal, setDeleteModal] = useState(false);
    const [contact, setContact] = useState<any>();

    const onClickUpdate = (arg: any) => {
        dispatch(getUser(arg.id))
    };


    useEffect(() => {
      dispatch(getTeams('', filter))
  
      return () => {
          dispatch(clearTeams())
      };
  }, [data]);

    const teams = useMemo(() =>
            data.map((item: any) => ({
                id: item.id.toString() || null,
                name: item.name  || null,
                email: item.email  || null,
                buyer_ai: item.buyer_ai  || null,
                team_id: item.team?.team_name  || null,
                total: item.total  || "--",
                created_at: moment(item.created_at).format("YYYY-MM-DD")  || null,
                roles: item.roles || []
            })).filter((item: any) => item !== null),
        [data]
    );

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
          {
            Header: "Email",
            accessor: "email",
            filterable: true,
            disableFilters: true,
            sortable: true,
          },
            {
            Header: "Roles",
            accessor: "roles",
            filterable: true,
            disableFilters: true,
            sortable: false,
                Cell: (cellProps: any) => {
                    return (
                        cellProps.row.original.roles.map((i: any) => <Badge key={Math.random()} className="bg-info-subtle text-info">{i.role_name}</Badge> )
                    )
                }
          },
          {
            Header: "Total",
            accessor: "total",
            filterable: true,
            disableFilters: true,
            sortable: true,
          },
          {
            Header: "Buyer AI",
            accessor: "buyer_ai",
            filterable: true,
            disableFilters: true,
            sortable: true,
          },
          {
            Header: "Team",
            accessor: "team_id",
            filterable: true,
            disableFilters: true,
            sortable: true,
          },
            {
                Header: "Created At",
                accessor: "created_at",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Action",
                Cell: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-3">
                            {
                            hasPermission("update-users") && (
                                    <Link className="text-success" to="#" onClick={() => {
                                        const userData = cellProps.row.original;
                                        onClickUpdate(userData)
                                    }}>
                                        <i className="mdi mdi-pencil font-size-18" ></i>
                                    </Link>
                                )
                            }

                            {
                            hasPermission("delete-users") && 
                                <Link className="text-danger" to="#">
                                    <i
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={() => {
                                            const team = cellProps.row.original;
                                            onClickDelete(team);
                                        }}
                                    ></i>
                                </Link>
                            }
                        </div>
                    );
                },
            },
        ], []);

    const onClickDelete = (arg: any) => {
        setContact(arg);
        setDeleteModal(true);
    };

    const handleDeleteTeam = () => {
        dispatch(delUser(contact.id));
        setDeleteModal(false);
    };

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={teams}
                isGlobalFilter={true}
                customPageSize={pageSize}
                className="table align-middle datatable dt-responsive table-check nowrap"
            />

            {deleteModal && <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteTeam}
                onCloseClick={() => setDeleteModal(false)}
            />}
        </React.Fragment>
    )
})
TableTeam.displayName = "TableTeam";
export default TableTeam