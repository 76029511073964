import { call, put, takeEvery } from "redux-saga/effects"
import { BrokerTypes } from "./actionsTypes"


import {
    getBrokersSuccess,
    getBrokerSuccess,
    getBrokersIdSuccess,
    getBrokerFail,
    addBrokerFail,
    addBrokerSuccess,
    updBrokerFail,
    updBrokerSuccess,
    modalBroker,
    delBrokerSuccess,delBrokerFail
} from "./actions";


import {
    getBroker as getBrokerApi,
    getBrokers as getBrokersApi,
    postBroker as postBrokerApi,
    updateBroker as updateBrokerApi,
    delBroker as delBrokerApi,
    getBrokersId as getBrokersIdApi,
} from "../../helpers/backend_helper";


// function generics
function* fetchBrokers({next_page, filter}: any){
    try{
        const response : Promise<any> = yield call(getBrokersApi, next_page, filter);

        yield put(getBrokersSuccess(response))
    }catch (error){
        yield put(getBrokerFail(error));
    }
}

function* fetchBroker({id}: any){
    try{
        const response: Promise<any> = yield call(getBrokerApi, id);
        yield put(getBrokerSuccess(response));
        yield put(modalBroker(true))
    }catch(error){
        yield put(addBrokerFail(error));
    }
}

// this method we use only for get brokers id for select in price day component
function* fetchBrokersId(){
    try{
    const response: Promise<any> = yield call(getBrokersIdApi);
    yield put(getBrokersIdSuccess(response));
    }catch(error){
        yield put(addBrokerFail(error));
    }
}


function* addBrokerSaga({data}: any) {
    try {
      const response: Promise<any> = yield call(postBrokerApi, data);
      yield put(addBrokerSuccess(response));
      yield put(modalBroker(false))
    } catch (error) {
      yield put(addBrokerFail(error));
    }
}

function* updateBrokerSaga({data, id} : any) {
    try{
      const response : Promise<any> = yield call(updateBrokerApi, data, id);
      yield put(updBrokerSuccess(response));
      yield put(modalBroker(false))
    }catch (error) {
      yield put(updBrokerFail(error))
    }
  }


function* delBrokerSaga({id}: any){
    try{
        yield call(delBrokerApi, id );
        yield put(delBrokerSuccess(id));
    }catch (error){
        yield put(delBrokerFail(error));
    }
}



function* brokersSaga() {
    yield takeEvery(BrokerTypes.GET_BROKERS, fetchBrokers)
    yield takeEvery(BrokerTypes.GET_BROKER, fetchBroker)
    yield takeEvery(BrokerTypes.ADD_BROKER, addBrokerSaga)
    yield takeEvery(BrokerTypes.UPD_BROKER, updateBrokerSaga)
    yield takeEvery(BrokerTypes.DEL_BROKER, delBrokerSaga)
    yield takeEvery(BrokerTypes.GET_BROKERS_ID, fetchBrokersId)
}
  export default brokersSaga;