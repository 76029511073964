import React from 'react'
import { Alert, Container, Row, Col } from 'reactstrap';
import CarouselPage from '../Authentication/CarouselPage';
import { Link } from 'react-router-dom';

// import images
import logo from "../../assets/images/logo-sm.svg";


const NoAccess = () => {
    return (
        <div className="auth-page">
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4} md={5} className="col-xxl-3">
                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5 text-center">
                        <Link to="/dashboard" className="d-block auth-logo">
                          <img src={logo} alt="" height="28" />{" "}
                          <span className="logo-txt">Company</span>
                        </Link>
                      </div>
                      <div className="auth-content my-auto">
                        <div className="text-center">
                          <h2 className="mb-0"> Sorry! </h2>
                          <p className="text-muted mt-2">
                          You don't have access to this page. <br />
                          Please contact your administrator.  
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <CarouselPage />
            </Row>
          </Container>
        </div>
  )
}

export default NoAccess;