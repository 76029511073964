import { OrdersTypes, OrdersState } from './actionsTypes';

export const INIT_STATE: OrdersState = {
    data: [],
    item: {},
    pagination: {},
    error: {},
    loading: false,
    loaded: false,
    isOpenModal: false,
}
const orders = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case OrdersTypes.GET_ORDERS:
            return {
                ...state,
                loading: true
            }
        case OrdersTypes.GET_ORDERS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: action.payload.pagination,
                loaded: true,
                loading: false
            }

        case OrdersTypes.GET_ORDERS_BY_TEAM:
            return {
                ...state,
                loading: true
            }
        case OrdersTypes.GET_ORDERS_BY_TEAM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loaded: true,
                loading: false
            }

            case OrdersTypes.GET_ORDER_SUCCESS:
                return {
                    ...state,
                    item: action.payload.data,
                    isOpenModal: true,
                }

        case OrdersTypes.ADD_ORDERS_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
            }
        case OrdersTypes.DEL_ORDERS_SUCCESS:
            return {
                ...state,
                data: state.data.filter((item: any) => item.id.toString() !== action.payload.toString()),
                error: {},
                loading: false,
                loaded: true
            }

        case OrdersTypes.CLEAR_ORDERS:
            return {
                ...state,
                item: {},
                error: {},
            }


        case OrdersTypes.UPD_ORDERS_SUCCESS:
            const items = state.data.map((item: any) => {
                return (item.id === action.payload.id) ? action.payload : item
            })
            return {
                ...state,
                data: items,
                error: {},
                loading: false,
                loaded: true
            }

        case OrdersTypes.ORDERS_FAIL:
            console.log(action.payload);
            return {
                ...state,
                error: action.payload,
                loaded: false,
                loading: false
            }
        case OrdersTypes.MODAL_ORDERS:
            return {
                ...state,
                isOpenModal: action.status
            }
        default:
            return state
    }
}

export default orders