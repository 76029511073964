import {TrakerTypes} from "./actionTypes"


export const getTraker = () => ({
    type: TrakerTypes.GET_TRAKER,
  })

  export const getUpdateBrokerName = () => ({
    type: TrakerTypes.GET_UPDATE_BROKER_NAME,
  })
  
  export const getTrakerSuccess = (data: any) => ({
    type: TrakerTypes.GET_TRAKER_SUCCESS,
    data,
  })

  export const modalTraker = (status: boolean) => ({ 
    type: TrakerTypes.MODAL_TRAKER,
    status
})



export const addCapa =(data: any) =>({
  type: TrakerTypes.ADD_СAPA,
  data
})

export const addCapaSuccess = (data: any) => ({
  type: TrakerTypes.ADD_CAPA_SUCCESS,
  payload: data
})

export const addCapaFail = (error : any) => ({
  type: TrakerTypes.CAPA_FAIL,
  payload: error,
})