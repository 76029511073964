import { CheckinTypes, CheckinState } from './actionsTypes';



export const INIT_STATE: CheckinState = {
    data: [],
    daysInMonth: [],
    error: {},
    loading: false,
    loaded: false,
    isOpenModal: false,
}

const checkin = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case CheckinTypes.GET_CHECKINS:
            return {
                ...state,
                loading: true
            }
        case CheckinTypes.GET_CHECKINS_SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                daysInMonth: action.payload.data.daysInMonth,
                loaded: true,
                loading: false
            }
        case CheckinTypes.CLEAR_CHECKINS: return INIT_STATE;


        case CheckinTypes.CHECKINS_FAIL:
            return {
              ...state,
              error: action.payload,
              loaded: false,
              loading: false
            }
        case CheckinTypes.MODAL_CHECKIN:
            return{
                ...state,
                isOpenModal: action.status
            }


        case CheckinTypes.ADD_USER_CHECKIN_SUCCESS:
            return{
                ...state,
                data: [...state.data, action.payload],
            }


        default:
            return state
    }
}


export default checkin