import { call, put, takeEvery } from "redux-saga/effects"
import { OrdersTypes } from "./actionsTypes"

import {
    getOrders as getOrdersApi,
    getOrder as getOrderApi,
    postOrders as postOrdersApi,
    updateOrders as updateOrdersApi,
    delOrders as delOrdersApi,
    getOrdersByTeam as getOrdersByTeamApi
  } from "../../helpers/backend_helper";
import {
    addOrdersSuccess,
    delOrdersFail,
    delOrdersSuccess,
    getOdersSuccess,
    getOrdersByTeamSuccess,
    getOrdersSuccess,
    modalOrders,
    updOrdersSuccess
} from "./actions";


  function* fetchOrders({next_page, filter}: any) {
    try {
      const response : Promise<any> = yield call(getOrdersApi, next_page, filter);
      yield put(getOdersSuccess(response))
    } catch (error) {
      yield put(delOrdersFail(error))
    }
  }

function* fetchOrdersByTeam({id}: any) {
    try {
        const response : Promise<any> = yield call(getOrdersByTeamApi, id);
        yield put(getOrdersByTeamSuccess(response))
    } catch (error) {
        yield put(delOrdersFail(error))
    }
}
  
  function* fetchOrder({id}: any ) {
    try {
      const response: Promise<any> = yield call(getOrderApi, id);
        if(response) {
            yield put(getOrdersSuccess(response));
        }
    } catch (error) {
      yield put(delOrdersFail(error));
    }
  }

  function* addOrdersSaga({data}: any) {
    try {
      const response: Promise<any> = yield call(postOrdersApi, data);
      yield put(addOrdersSuccess(response));
      yield put(modalOrders(false))
    } catch (error) {
      yield put(delOrdersFail(error));
    }
  }
  
  function* updateOrdersSaga({data, id} : any) {
    try{
      const response : Promise<any> = yield call(updateOrdersApi, data, id);
      yield put(updOrdersSuccess(response));
      yield put(modalOrders(false))
    }catch (error) {
      yield put(delOrdersFail(error))
    }
  }
  
  function* delOrdersSaga({id} : any) {
    try{
      yield call(delOrdersApi, id);
      yield put(delOrdersSuccess(id));
    }catch (error) {
      yield put(delOrdersFail(error))
    }
  }


function* OrdersSaga() {
    yield takeEvery(OrdersTypes.GET_ORDERS, fetchOrders)
    yield takeEvery(OrdersTypes.GET_ORDER, fetchOrder)
    yield takeEvery(OrdersTypes.GET_ORDERS_BY_TEAM, fetchOrdersByTeam)
    yield takeEvery(OrdersTypes.ADD_ORDERS, addOrdersSaga)
    yield takeEvery(OrdersTypes.UPD_ORDERS, updateOrdersSaga)
    yield takeEvery(OrdersTypes.DEL_ORDERS, delOrdersSaga)
  }
  
  export default OrdersSaga;