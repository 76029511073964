
import {  call, put, takeEvery } from "redux-saga/effects";
import { StatisticTypes } from "./actionsTypes";
import { getStatisticFail, getSuccessStatistic } from "./actions";
import {getStatistic as getStatisticApi} from "../../helpers/backend_helper"

function* fetchStatistic({filter}:any) {
    try {
      const response: Promise<any> = yield call(getStatisticApi, filter);
      yield put(getSuccessStatistic(response));
    } catch (error) {
      yield put(getStatisticFail(error));
    }
  }

function* statisticSaga() {
    yield takeEvery(StatisticTypes.GET_STATISTIC, fetchStatistic)
}  

export default statisticSaga;