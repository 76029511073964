import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Container, Row, Col } from 'reactstrap' ;
import TableUser from "./components/TableTeam";
import NoSearchResult from "../../components/Common/NoSearchResult";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPermissions, modalPermissions } from 'src/store/permissions/actions';
import ModalTeam from './components/Modal';
import usePermissions from 'src/hooks/usePermissions';


const Permissions = () => {
    document.title = "Permissions | Dashboard";
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {hasPermission} = usePermissions();

    const data = useSelector((state: any) => state.permissions.data || []);
    const loading = useSelector((state: any) => state.permissions.loading || false);
    const isOpenModal = useSelector((state: any) => state.permissions.isOpenModal);   

    useEffect(() => {
        dispatch(getPermissions())
    }, []);

    const handleUserClicks = () => {
        dispatch(modalPermissions(true))
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Permissions" />

                    <Row className="align-items-center">
                        <Col md={12}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    {
                                    // hasPermission("create-permissions") &&
                                    <Link to="#" className="btn btn-light" onClick={handleUserClicks}>
                                        <i className="bx bx-plus me-1"></i> Add New
                                    </Link>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    { data.length > 0 && <TableUser data={data} pageSize={20}/> }
                                    { loading ? ( <NoSearchResult /> ) : null }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
                {isOpenModal && <ModalTeam isOpen={isOpenModal} />}
            </div>
        </React.Fragment>
  )
}

export default Permissions;