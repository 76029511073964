import Checkin from "src/pages/Checkin";
import { CheckinTypes } from "./actionsTypes";


export const getCheckin = (filter: any) =>({
    type: CheckinTypes.GET_CHECKINS,
    filter,
})


export const getCheckinSuccess = (data : any) =>({
    type: CheckinTypes.GET_CHECKINS_SUCCESS,
    payload: data,
})


export const clearCheckin = () => ({
    type: CheckinTypes.CLEAR_CHECKINS,
})


export const getCheckinFail = (error : any) => ({
    type: CheckinTypes.CHECKINS_FAIL,
    payload: error,
})


export const modalCheckin = (status: boolean) => ({
    type: CheckinTypes.MODAL_CHECKIN,
    status
})

export const addNewUserForCheckin =(data: any) =>({
    type: CheckinTypes.ADD_USER_CHECKIN,
    data
})

export const addNewUserForCheckinSuccess = (data: any) => ({
    type: CheckinTypes.ADD_USER_CHECKIN_SUCCESS,
    payload: data
  })

