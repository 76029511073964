import {TeamTypes, TeamState} from "./actionTypes"

export const INIT_STATE : TeamState = {
  data: [],
  item: {},
  members: [],
  pagination: {},
  error: {},
  loading: false,
  loaded: false,
  isOpenModal: false,
}

const teams = (state = INIT_STATE, action :any) => {
  switch (action.type) {
    case TeamTypes.GET_TEAMS:
      return {
        ...state,
        loading: true
      }

    case TeamTypes.GET_TEAMS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loaded: true,
        loading: false
      }

    case TeamTypes.CLEAR_TEAMS:
        return INIT_STATE


    case TeamTypes.GET_TEAM_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }

    case TeamTypes.CLEAR_TEAM:
      return {
        ...state,
        item: {},
        error: {},
      }

    case TeamTypes.ADD_TEAM_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
      }

    case TeamTypes.UPD_TEAM_SUCCESS:
      const items = state.data.map((item : any) => {
        return (item.id === action.payload.id) ? action.payload : item
      })
      return {
        ...state,
        data: items,
        error: {},
        loading: false,
        loaded: true
      }

    case TeamTypes.DEL_TEAM_SUCCESS:
      return {
        ...state,
        data: state.data.filter((item : any) => item.id.toString() !== action.payload.toString() ),
        error: {},
        loading: false,
        loaded: true
      }

      case TeamTypes.GET_TEAM_MEMBERS:
            return {
                ...state,
                loading: true
            }
        case TeamTypes.GET_TEAM_MEMBERS_SUCCESS:
            return {
                ...state,
                members: [...action.payload],
                loaded: true,
                loading: false
            }

    case TeamTypes.TEAM_FAIL:
      return {
        ...state,
        error: action.payload,
        loaded: false,
        loading: false
      }

    case TeamTypes.MODAL_TEAM:
      return {
        ...state,
        isOpenModal: action.status
      }

    default:
      return state
  }
}

export default teams
