import { SpendsTypes, SpendsState } from './actionsTypes';
export const INIT_STATE: SpendsState = {
    data: [],
    item: {},
    pagination: {},
    error: {},
    loading: false,
    loaded: false,
    isOpenModal: false,
}
const spends = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case SpendsTypes.GET_SPENDS:
            return {
                ...state,
                loading: true
            }
        case SpendsTypes.GET_SPENDS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: action.payload.pagination,
                loaded: true,
                loading: false
            }
        case SpendsTypes.CLEAR_SPENDS: return INIT_STATE;

        case SpendsTypes.ADD_SPEND_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload.data],
            }
        case SpendsTypes.DEL_SPEND_SUCCESS:
            return {
                ...state,
                data: state.data.filter((item: any) => item.id.toString() !== action.payload.toString()),
                error: {},
                loading: false,
                loaded: true
            }

        case SpendsTypes.CLEAR_SPEND:
            return {
                ...state,
                item: {},
                error: {},
            }

        case SpendsTypes.GET_SPEND_SUCCESS:
            return {
                ...state,
                item: action.payload.data,
            }

        case SpendsTypes.UPD_SPEND_SUCCESS:
            const items = state.data.map((item: any) => {
                return (item.id === action.payload.data.id) ? action.payload.data : item
            })
            return {
                ...state,
                data: items,
                error: {},
                loading: false,
                loaded: true
            }

        case SpendsTypes.SPEND_FAIL:
            return {
                ...state,
                error: action.payload,
                loaded: false,
                loading: false
            }
        case SpendsTypes.MODAL_SPEND:
            return {
                ...state,
                isOpenModal: action.status
            }
        default:
            return state
    }
}

export default spends