import { StatisticTypes } from "./actionsTypes";


export const getStatistic = (filter:any) =>({
    type: StatisticTypes.GET_STATISTIC,
    filter
})


export const getSuccessStatistic = (data : any) =>({
    type: StatisticTypes.GET_STATISTIC_SUCCESS,
    payload: data,
})


export const clearStatistic = () => ({
    type: StatisticTypes.CLEAR_STATISTIC,
})


export const getStatisticFail = (error : any) => ({
    type: StatisticTypes.STATISTIC_FAIL,
    payload: error,
})


