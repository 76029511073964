import { call, put, takeEvery } from "redux-saga/effects"
import { getDashboardFailure, getDashboardSuccess } from "./actions";

import {
    getTeamsAll as getTeamsAllApi,
  } from "../../helpers/backend_helper"
import { DashboardTypes } from "./actionTypes";

function* fetchDashboard() {
    try {
      const response: Promise<any> = yield call(getTeamsAllApi);
      yield put(getDashboardSuccess(response));
    } catch (error) {
      yield put(getDashboardFailure(error));
    }
  }


  function* dashboardSaga() {
    yield takeEvery(DashboardTypes.GET_DASHBOARD, fetchDashboard)
  }

  export default dashboardSaga;  