export enum OrdersTypes {
    GET_ORDERS = "@@orders/GET_ORDERS",
    GET_ORDERS_SUCCESS = "@@orders/GET_ORDERS_SUCCES",

    CLEAR_ORDERS = "@@orders/CLEAN_ORDERS",

    GET_ORDER = "@@orders/GET_ORDER",
    GET_ORDER_SUCCESS = "@@team/GET_ORDER_SUCCESS",

    ADD_ORDERS = "@@orders/ADD_ORDERS",
    ADD_ORDERS_SUCCESS = "@@orders/ADD_ORDERS_SUCCESS",

    UPD_ORDERS = "@@orders/UPD_ORDERS",
    UPD_ORDERS_SUCCESS = "@@orders/UPD_ORDERS_SUCCESS",

    DEL_ORDERS = "@@orders/DEL_ORDERS",
    DEL_ORDERS_SUCCESS = "@@orders/DEL_ORDERS_SUCCESS",

    GET_ORDERS_BY_TEAM = "@@orders/GET_ORDERS_BY_TEAM",
    GET_ORDERS_BY_TEAM_SUCCESS = "@@orders/GET_ORDERS_BY_TEAM_SUCCES",

    ORDERS_FAIL ="@@orders/SPEND_FAIL",
    MODAL_ORDERS = "@@orders/MODAL_ORDERS",
}

export interface OrdersState {
    data  : Array<any>;
    item: Object;
    pagination: Object;
    error : Object;
    loading: boolean,
    loaded: boolean,
    isOpenModal: boolean,
}