
import React, { useEffect, useMemo, useState } from "react";
import { ModalBody, ModalHeader, Modal, Row, Col, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import * as yup from "yup";
import { Formik, Form as FormikForm, useFormik } from "formik";
import { modalCheckin } from "src/store/actions"

import LabelInput from "../../../components/Common/LabelInput";

import { addNewUserForCheckin } from "src/store/actions";



interface Props {
    isOpen: boolean;
}

interface IFormValues {
    name: string,
}
const validationSchema = yup.object().shape({
    name: yup.string()
      .min(1, "min 1 character")
      .max(10, "max 10 characters")
      .matches(/^[0-9a-zA-Z]+$/, "Only numbers and Latin letters are allowed")
      .required("required"),
});

const ModalChekin = ({ isOpen }: Props) => {
    const item = useSelector((state: any) => state.Spend.item || {});
    const dispatch = useDispatch();


    const initialValues: IFormValues = {
        name: item?.name || "",
    };


    const handleSubmitForm = async (values: any) => {

        const data = {
            "name" :values.name
        }



        if (values.name) {
            await dispatch(addNewUserForCheckin(data));
        }
    };


    const handlerClickSubmit = async () => {
        const curErrors = await validateForm();
        const curErrorsKeys = Object.keys(curErrors);
        if (curErrorsKeys.length) {
          const el = document.getElementById(curErrorsKeys[0]);
          if (el) el.focus();
        }
        submitForm();
      };



    const {
        values,
        validateForm,
        handleChange,
        submitForm,
        handleBlur,
        errors,
        touched,
        isValid,
        resetForm,
        setFieldValue
    } = useFormik({
        initialValues: initialValues,
        onSubmit: handleSubmitForm,
        validationSchema: validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true
    });



    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={() => dispatch(modalCheckin(false))} tag="h4">
                {"Add"} New Employee
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                >
                        <FormikForm>
                            <Row>
                                <div className="mb-3">
                                    <LabelInput
                                        label="Name*"
                                        placeholder="Name*"
                                        name="name"
                                        // onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue("capa", e.target.value)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name || ""}
                                        hasError={!!(errors.name && touched.name)}
                                        errorText={errors.name}
                                    />
                                </div>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="text-end">
                                        <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            onClick={handlerClickSubmit}
                                            disabled={!isValid }
                                        >
                                            Save
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </FormikForm>

                </Formik>
            </ModalBody>
        </Modal>
    )
}



export default ModalChekin;