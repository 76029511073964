export enum CheckinTypes {
    GET_CHECKINS = "@@checkin/GET_CHECKINS",
    GET_CHECKINS_SUCCESS = "@@checkin/GET_CHECKINS_SUCCESS",
    CLEAR_CHECKINS = "@@checkin/CLEAR_CHECKINS",
    CHECKINS_FAIL = "@@checkin/CHECKINS_FAIL",
    MODAL_CHECKIN = "@@checkin/MODAL_CHECKIN",

    ADD_USER_CHECKIN = "@@checkin/ADD_USER_CHECKIN",
    ADD_USER_CHECKIN_SUCCESS = "@@team/ADD_USER_CHECKIN_SUCCESS",
}

export interface CheckinState {
    data  : Array<any>;
    daysInMonth: Array<any>;
    error: {},
    loading: boolean,
    loaded: boolean,
    isOpenModal: boolean,
}