import React, { useEffect, useMemo } from "react";
import {Form, ModalBody, ModalHeader, Modal, Row, Col, Input} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import LabelInput from "../../../components/Common/LabelInput";
import { addPermission, clearPermission, modalPermissions, updPermissions } from "src/store/actions";

interface Props {
  isOpen: boolean;
  //toggle: () => void;
}
interface IFormValues {
  name: string;
}
const ModalTeam = ({isOpen} : Props) => {
  const dispatch = useDispatch();

  const item = useSelector((state: any) => state.permissions.item || {});

  const validationSchema = yup.object().shape({
    name: yup.string()
      .required("required"),
  });

  const initialValues = useMemo((): IFormValues => {
    return {
      name: item?.name || "",
    }
  }, [item]);

  const handleSubmitForm = () => {
    if(item.id) {
      dispatch( updPermissions({ "name": values.name}, item.id  ));
    } else {
      dispatch( addPermission({ "name": values.name, }) );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearPermission());
    };
  }, [])

  const {
    values,
    validateForm,
    handleChange,
    submitForm,
    handleBlur,
    errors,
    touched,
    isValid,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmitForm,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true
  });

  const handlerClickSubmit = async () => {
    const curErrors = await validateForm();
    const curErrorsKeys = Object.keys(curErrors);
    if (curErrorsKeys.length) {
      const el = document.getElementById(curErrorsKeys[0]);
      if (el) el.focus();
    }
    submitForm();
  };

  const close = () => {
    dispatch(modalPermissions(false));
    resetForm();
  };

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close} tag="h4">
        {item?.id  ? "Edit" : "Add"} Permissions
      </ModalHeader>
      <ModalBody>
        <Form className="needs-validation">
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <LabelInput
                  label="Name*"
                  placeholder="Name*"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name || ""}
                  hasError={!!(errors.name && touched.name)}
                  errorText={errors.name}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-success save-user"
                  onClick={handlerClickSubmit}
                  disabled={!isValid}
                > Save </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalTeam;