import { call, put, takeEvery } from "redux-saga/effects"
import { PermissionsTypes } from "./actionTypes"
import {
    getPermissionsSuccess,
    getPermissionsFailure,
    getPermissionSuccess,
    getPermissionFailure,
    addPermissionSuccess,
    addPermissionFail,
    updPermissionsSuccess,
    updPermissionsFail,
    delPermissionsSuccess,
    delPermissionsFail,
    modalPermissions
} from "./actions";
import {
    getPermission as gerPermissionApi,
    getPermissions as getPermissionsApi,
    postPermission as postPermissionApi,
    updatePermission as updatePermissionApi,
    delPermission as delPermissionApi,
  } from "../../helpers/backend_helper"


function* fetchPermissions() {
    try {
      const response: Promise<any> = yield call(getPermissionsApi);
      yield put(getPermissionsSuccess(response));
    } catch (error) {
      yield put(getPermissionsFailure(error));
    }
  }

  function* fetchPermission({id}: any ) {
    try {
      const response: Promise<any> = yield call(gerPermissionApi, id);
      yield put(getPermissionSuccess(response));
    } catch (error) {
      yield put(getPermissionFailure(error));
    }
  }

  function* addPermissionSaga({data}: any) {
    try {
      const response: Promise<any> = yield call(postPermissionApi, data);
      yield put(addPermissionSuccess(response));
      yield put(modalPermissions(false))
    } catch (error) {
      yield put(addPermissionFail(error));
    }
  }

  function* updateRolesSaga({data, id} : any) {
    try{
      const response : Promise<any> = yield call(updatePermissionApi, data, id);
      yield put(updPermissionsSuccess(response));
      yield put(modalPermissions(false))
    }catch (error) {
      yield put(updPermissionsFail(error))
    }
  }

  function* delPermissionSaga({id} : any) {
    try{
      yield call(delPermissionApi, id);
      yield put(delPermissionsSuccess(id));
    }catch (error) {
      yield put(delPermissionsFail(error))
    }
  }

function* permissionsSaga() {
    yield takeEvery(PermissionsTypes.GET_PERMISSIONS, fetchPermissions)
    yield takeEvery(PermissionsTypes.GET_PERMISSION, fetchPermission)
    yield takeEvery(PermissionsTypes.ADD_PERMISSIONS, addPermissionSaga)
    yield takeEvery(PermissionsTypes.UPD_PERMISSIONS, updateRolesSaga)
    yield takeEvery(PermissionsTypes.DEL_PERMISSIONS, delPermissionSaga)
  }

  export default permissionsSaga;  