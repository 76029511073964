import { PriceDayTypes, PriceDayState } from './actionsTypes';
export const INIT_STATE: PriceDayState = {
    data: [],
    item: {},
    pagination: {},
    error: {},
    loading: false,
    loaded: false,
    isOpenModal: false,
}
const priceDay = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case PriceDayTypes.GET_PRICES_DAY:
            return {
                ...state,
                loading: true
            }
        case PriceDayTypes.GET_PRICES_DAY_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: action.payload.pagination,
                loaded: true,
                loading: false
            }
        case PriceDayTypes.CLEAR_PRICES_DAY:
            return INIT_STATE;

        case PriceDayTypes.ADD_PRICE_DAY_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.payload.data],
            }
        case PriceDayTypes. DEL_PRICE_DAY_SUCCESS:
            return {                
                ...state,
                data: state.data.filter((item: any) => item.id.toString() !== action.payload.toString()),
                error: {},
                loading: false,
                loaded: true
            }

        case PriceDayTypes.CLEAR_PRICE_DAY:
            return {
                ...state,
                item: {},
                error: {},
            }

        case PriceDayTypes.GET_PRICE_DAY_SUCCESS:
            return {
                ...state,
                item: action.payload.data,
            }

        case PriceDayTypes.UPD_PRICE_DAY_SUCCESS:
            const items = state.data.map((item: any) => {

                return (item.id === action.payload.id) ? action.payload : item
            })
            return {
                ...state,
                data: items,
                error: {},
                loading: false,
                loaded: true
            }

        case PriceDayTypes.PRICES_DAY_FAIL:
            console.log(action.payload);
            return {
                ...state,
                error: action.payload,
                loaded: false,
                loading: false
            }
        case PriceDayTypes.MODAL_PRICES_DAY:
            return {
                ...state,
                isOpenModal: action.status
            }
        default:
            return state
    }
}

export default priceDay