import React, {useEffect} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {clearTeams, getTeams, modalTeam} from "../../store/team/actions";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import TableTeam from "./components/TableTeam";
import NoSearchResult from "../../components/Common/NoSearchResult";
import ModalTeam from "./components/Modal";
import usePermissions from "src/hooks/usePermissions";

const Teams = () => {
    document.title = "Team List | Dashboard";
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {hasPermission} = usePermissions();

    const data = useSelector((state: any) => state.Teams.data || []);
    const loading = useSelector((state: any) => state.Teams.loading || false);
    const isOpenModal = useSelector((state: any) => state.Teams.isOpenModal || false);

    let filter = {'per_page': 5};

    useEffect(() => {
        dispatch(getTeams('', filter))

        return () => {
            dispatch(clearTeams())
        };
    }, []);

    const handleUserClicks = () => {
        dispatch(modalTeam(true))
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Teams" />

                    <Row className="align-items-center">
                        <Col md={12}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    {
                                        hasPermission("create-teams") &&
                                    <Link to="#" className="btn btn-light" onClick={handleUserClicks}>
                                        <i className="bx bx-plus me-1"></i> Add New
                                    </Link>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    { data.length > 0 && <TableTeam data={data} pageSize={20}/> }
                                    { loading ? ( <NoSearchResult /> ) : null }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>

                {isOpenModal && <ModalTeam isOpen={isOpenModal} />}
            </div>
        </React.Fragment>
    );
};

export default Teams;