import {useSelector} from "react-redux";

const usePermissions = (_permissions = []) => {

    const user = JSON.parse(localStorage.getItem('authUser') || "{}");
    const permissions = user.roles?.flatMap((role: any) => role.permissions);
      return {
      permissions,
      hasPermission: (permission: any) => permissions?.includes(permission)
    };

    // const { data } = useSelector((state: any) => state.login)

    // const permissions = data.roles?.flatMap((role: any) => role.permissions);
    //   return {
    //   permissions,
    //   hasPermission: (permission: any) => permissions?.includes(permission)
    // };
  };
  

  export default usePermissions;