export enum UserTypes {
    GET_USERS = "@@user/GET_USERS",
    GET_USERS_SUCCESS = "@@user/GET_USERS_SUCCESS",
    CLEAR_USERS = "@@user/CLEAR_USERS",
    GET_USER = "@@user/GET_USER",
    GET_USER_SUCCESS = "@@user/GET_USER_SUCCESS",
    CLEAR_USER = "@@user/CLEAR_USER",
    ADD_USER = "@@user/ADD_USER",
    ADD_USER_SUCCESS = "@@user/ADD_USER_SUCCESS",
    UPD_USER = "@@user/UPD_USER",
    UPD_USER_SUCCESS = "@@user/UPD_USER_SUCCESS",
    DEL_USER = "@@user/DEL_USER",
    DEL_USER_SUCCESS = "@@user/DEL_USER_SUCCESS",

    USER_FAIL = "@@user/USER_FAIL",
    MODAL_USER = "@@user/MODAL_USER"
}

export interface UserState {
    data  : Array<any>;
    item: Object
    pagination: Object
    error : Object;
    loading: boolean,
    loaded: boolean,
    isOpenModal: boolean,
}
