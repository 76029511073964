import { call, put, takeEvery } from "redux-saga/effects"
import { SpendsTypes } from "./actionsTypes"

import {
    getSpendsSuccess,
    getSpendSuccess,
    getSpendFail,
    addSpendFail,
    addSpendSuccess,
    updSpendFail,
    updSpendSuccess,
    modalSpend,
    delSpendSuccess,
    delSpendFail
} from "./actions";

import {
    getSpends as getSpendsApi,
    getSpend as getSpendApi,
    postSpend as postSpendApi,
    updateSpend as updateSpendApi,
    delSpend as delSpendApi
  } from "../../helpers/backend_helper";


  function* fetchSpends({next_page, filter}: any) {
    try {
      const response : Promise<any> = yield call(getSpendsApi, next_page, filter);
      yield put(getSpendsSuccess(response))
    } catch (error) {
      yield put(getSpendFail(error))
    }
  }
  
  function* fetchSpend({id}: any ) {
    try {
      const response: Promise<any> = yield call(getSpendApi, id);
        if(response) {
            yield put(getSpendSuccess(response));
            yield put(modalSpend(true))
        }
    } catch (error) {
      yield put(updSpendFail(error));
    }
  }

  function* addSpendSaga({data}: any) {
    try {
      const response: Promise<any> = yield call(postSpendApi, data);
      yield put(addSpendSuccess(response));
      yield put(modalSpend(false))
    } catch (error) {
      yield put(addSpendFail(error));
    }
  }
  
  function* updateSpendSaga({data, id} : any) {
    try{
      const response : Promise<any> = yield call(updateSpendApi, data, id);
      yield put(updSpendSuccess(response));
      yield put(modalSpend(false))
    }catch (error) {
      yield put(updSpendFail(error))
    }
  }
  
  function* delSpendSaga({id} : any) {
    try{
      yield call(delSpendApi, id);
      yield put(delSpendSuccess(id));
    }catch (error) {
      yield put(delSpendFail(error))
    }
  }


function* spendSaga() {
    yield takeEvery(SpendsTypes.GET_SPENDS, fetchSpends)
    yield takeEvery(SpendsTypes.GET_SPEND, fetchSpend)
    yield takeEvery(SpendsTypes.ADD_SPEND, addSpendSaga)
    yield takeEvery(SpendsTypes.UPD_SPEND, updateSpendSaga)
    yield takeEvery(SpendsTypes.DEL_SPEND, delSpendSaga)
  }
  
  export default spendSaga;