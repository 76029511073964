import React from "react";

interface Props {
    text?: string
}

const NoSearchResult: React.FC<Props> = ({ text }: Props) => {

    return (
        <>
            <div className="noresult">
                <div className="text-center">
                    {!text ?
                        <>
                            <i className="bi bi-exclamation-triangle text-warning display-5"></i>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                        </>
                        :
                        <>
                            <i className="bi bi-exclamation-triangle text-warning display-5"></i>
                            <h5 className="mt-2">{text}</h5>
                        </>
                    }

                </div>
            </div>
        </>
    )
};

export default NoSearchResult;