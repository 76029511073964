export enum PriceDayTypes{
    GET_PRICES_DAY = "@@price_day/GET_PRICES_DAY",
    GET_PRICES_DAY_SUCCESS = "@@price_day/GET_PRICES_DAY_SUCCESS",
    GET_PRICES_DAY_FAILURE = "@@price_day/GET_PRICES_DAY_FAILURE",
    CLEAR_PRICES_DAY = "@@price_day/CLEAR_PRICES_DAY",

    GET_PRICE_DAY = "@@price_day/GET_PRICE_DAY",
    GET_PRICE_DAY_SUCCESS = "@@price_day/GET_PRICE_DAY_SUCCESS",
    GET_PRICE_DAY_FAILURE = "@@price_day/GET_PRICE_DAY_FAILURE",
    CLEAR_PRICE_DAY = "@@price_day/CLEAR_PRICE_DAY",

    ADD_PRICE_DAY = "@@price_day/ADD_PRICE_DAY",
    ADD_PRICE_DAY_SUCCESS = "@@price_day/ADD_PRICE_DAY_SUCCESS",

    UPD_PRICE_DAY = "@@price_day/UPD_PRICES_DAY",
    UPD_PRICE_DAY_SUCCESS = "@@price_day/UPD_PRICES_DAY_SUCCESS",
    
    DEL_PRICE_DAY = "@@price_day/DEL_PRICES_DAY",
    DEL_PRICE_DAY_SUCCESS = "@@price_day/DEL_PRICES_DAY_SUCCESS",

    PRICES_DAY_FAIL = "@@price_day/PRICES_DAY_FAIL",
    MODAL_PRICES_DAY = "@@price_day/MODAL_PRICES_DAY",

}

export interface PriceDayState {
    data  : Array<any>;
    item: Object;
    pagination: Object;
    error : Object;
    loading: boolean,
    loaded: boolean,
    isOpenModal: boolean,
}