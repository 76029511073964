import React from "react";

import { Routes, Route, redirect } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useSelector } from "react-redux";

// Import all middleware
import RoleBasedAuth from "./routes/middleware/RoleBasedAuth";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import { createSelector } from 'reselect';


//api config
// import config from "./config";

const App = () => {
  const selectCalendar = createSelector(
    (state: any) => state.Layout,
    (state) => ({
      layoutType: state.layoutType,
    })
);

const { layoutType } = useSelector(selectCalendar);

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout: any = getLayout();
  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
          />
        ))}
          <Route>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <RoleBasedAuth>
                  <Layout>{route.component}</Layout>
                </RoleBasedAuth>
                }
              key={idx}
            />
          ))}
        </Route> 
      </Routes>
    </React.Fragment>
  );
};

export default App;
