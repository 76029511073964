export enum JiraTypes {
    GET_PROJECTS = "@@team/GET_PROJECTS",
    GET_PROJECTS_SUCCESS = "@@team/GET_PROJECTS_SUCCESS",
    CLEAR_PROJECTS = "@@team/CLEAR_PROJECTS",

    GET_JIRA_USERS = "@@team/GET_JIRA_USERS",
    GET_JIRA_USERS_SUCCESS = "@@team/GET_JIRA_USERS_SUCCESS",
    CLEAR_JIRA_USERS = "@@team/CLEAR_JIRA_USERS",

    GET_JIRA_STATISTICS = "@@team/GET_JIRA_STATISTICS",
    GET_JIRA_STATISTICS_SUCCESS = "@@team/GET_JIRA_STATISTICS_SUCCESS",
    CLEAR_JIRA_STATISTICS = "@@team/CLEAR_JIRA_STATISTICS",

    JIRA_FAIL = "@@team/JIRA_FAIL",
}

export interface JiraState {
    statistics: Array<any>;
    projects: Array<any>
    users: Array<any>;
    error: Object;
    loadingProjects: boolean,
    loadedProjects: boolean,
    loadingJiraUsers: boolean,
    loadedJiraUsers: boolean,
    loadingJiraStatistics: boolean,
    loadedJiraStatistics: boolean,
}
