import { PriceDayTypes } from "./actionsTypes";


export const getPricesDay = (next_page: string, filter: object) =>({
    type: PriceDayTypes.GET_PRICES_DAY,
    next_page,
    filter
})
export const getPricesDaySuccess = (data : any) =>({
    type: PriceDayTypes.GET_PRICES_DAY_SUCCESS,
    payload: data,
})

export const getPriceDay = (id: number) => ({
    type: PriceDayTypes.GET_PRICE_DAY,
    id
  })

  export const getPriceDaySuccess = (data : any) => ({
    type: PriceDayTypes.GET_PRICE_DAY_SUCCESS,
    payload: data,
  })

export const getPriceDayFail = (error : any) => ({
    type: PriceDayTypes.GET_PRICE_DAY_FAILURE,
    payload: error,
})

export const clearPricesDay = () => ({
    type: PriceDayTypes.CLEAR_PRICES_DAY,
})






export const clearPriceDay = () => ({
    type: PriceDayTypes.CLEAR_PRICE_DAY,
})

export const modalPriceDay = (status: boolean) => ({ 
    type: PriceDayTypes.MODAL_PRICES_DAY,
    status
})

export const addPriceDay =(data: any) =>({
    type: PriceDayTypes.ADD_PRICE_DAY,
    data
})

export const addPriceDaySuccess = (data: any) => ({
    type: PriceDayTypes.ADD_PRICE_DAY_SUCCESS,
    payload: data
  })

  export const addPriceDayFail = (error : any) => ({
    type: PriceDayTypes.GET_PRICE_DAY_FAILURE,
    payload: error,
  })


  export const updPriceDay = (data : any, id: number) => ({
    type: PriceDayTypes.UPD_PRICE_DAY,
    id, data
  })
  
  export const updPriceDaySuccess = (data: any) => ({
    type: PriceDayTypes.UPD_PRICE_DAY_SUCCESS,
    payload: data
  })
  
  export const updPriceDayFail = (error : any) => ({
    type: PriceDayTypes.PRICES_DAY_FAIL,
    payload: error,
  })


  export const delPriceDay = (id: number) => ({
    type: PriceDayTypes.DEL_PRICE_DAY,
    id
  })
  
  export const delPriceDaySuccess = (id: number) => ({
    type: PriceDayTypes.DEL_PRICE_DAY_SUCCESS,
    payload: id
  })
  
  export const delPriceDayFail = (error : any) => ({
    type: PriceDayTypes.PRICES_DAY_FAIL,
    payload: error,
  })