export enum PermissionsTypes{
    GET_PERMISSIONS = "@@permissions/GET_PERMISSIONS",
    GET_PERMISSIONS_SUCCESS = "@@permissions/GET_PERMISSIONS_SUCCESS",
    GET_PERMISSIONS_FAILURE = "@@permissions/GET_PERMISSIONS_FAILURE",

    GET_PERMISSION = "@@permissions/GET_PERMISSION",
    GET_PERMISSION_SUCCESS = "@@permissions/GET_PERMISSION_SUCCESS",
    GET_PERMISSION_FAILURE = "@@permissions/GET_PERMISSION_FAILURE",

    ADD_PERMISSIONS = "@@permissions/ADD_PERMISSIONS",
    ADD_PERMISSIONS_SUCCESS = "@@permissions/ADD_PERMISSIONS_SUCCESS",

    UPD_PERMISSIONS  = "@@permissions/UPD_PERMISSIONS ",
    UPD_PERMISSIONS_SUCCESS = "@@permissions/UPD_PERMISSIONS_SUCCESS",
    
    DEL_PERMISSIONS  = "@@permissions/DEL_PERMISSIONS ",
    DEL_PERMISSIONS_SUCCESS = "@@permissions/DEL_PERMISSIONS_SUCCESS",

    PERMISSIONS_FAIL = "@@permissions/PERMISSIONS_FAIL",

    PERMISSIONS_MODAL = "@@permissions/MODAL_USER",
    CLEAR_PERMISSION = "@@ermissions/CLEAR_PERMISSION",
}

export interface PermissionsState {
    data  : Array<any>;
    loading : boolean;
    error : any;
    isOpenModal: boolean,
    loaded: boolean,
    item: Object
}


