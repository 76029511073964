import { RolesTypes } from "./actionTypes"

export const getRoles = () => ({
    type: RolesTypes.GET_ROLES,
  })
  
  export const getRolesSuccess = (payload : any) => ({
    type: RolesTypes.GET_ROLES_SUCCESS,
    payload,
  })

export const getRolesFailure = (payload : any) => ({
    type: RolesTypes.GET_ROLES_FAILURE,
    payload
})

export const getRole = (id: number) => ({
  type: RolesTypes.GET_ROLE,
  id
})

export const getRoleSuccess = (payload : any) => ({
  type: RolesTypes.GET_ROLE_SUCCESS,
  payload,
})

export const getRoleFailure = (payload : any) => ({
  type: RolesTypes.GET_ROLE_FAILURE,
  payload
})

export const addRole = (data: any) => ({
  type: RolesTypes.ADD_ROLES,
  data
})

export const addRolesSuccess = (data: any) => ({
  type: RolesTypes.ADD_ROLES_SUCCESS,
  payload: data
})

export const addRolesFail = (error : any) => ({
  type: RolesTypes.ROLES_FAIL,
  payload: error,
})

export const updRoles = (data : any, id: number) => ({
  type: RolesTypes.UPD_ROLES,
  id, data
})

export const updRolesSuccess = (data: any) => ({
  type: RolesTypes.UPD_ROLES_SUCCESS,
  payload: data
})

export const updRolesFail = (error : any) => ({
  type: RolesTypes.ROLES_FAIL,
  payload: error,
})

export const delRoles = (id: number) => ({
  type: RolesTypes.DEL_ROLES,
  id
})

export const delRolesSuccess = (id: number) => ({
  type: RolesTypes.DEL_ROLES_SUCCESS,
  payload: id
})

export const delRolesFail = (error : any) => ({
  type: RolesTypes.ROLES_FAIL,
  payload: error,
})

export const modalRoles = (status: boolean) => ({
  type: RolesTypes.ROLES_MODAL,
  status
})

export const clearRole = () => ({
  type: RolesTypes.CLEAR_ROLE,
})