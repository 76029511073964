
import React, { useEffect, useMemo, useState } from "react";
import { ModalBody, ModalHeader, Modal, Row, Col, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import * as yup from "yup";
import {Formik, Form as FormikForm, Field, FieldArray, useFormikContext } from "formik";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

import Select from "../../../components/Common/Select";

import {clearSpend, modalSpend, addSpend, updSpend, getTeamMembers, getTeams, getOrdersByTeam} from "src/store/actions";
import AddDynamicInputFields from "./AddDynamicInputFields";
import LabelInput from "../../../components/Common/LabelInput";


interface Props {
  isOpen: boolean;
}

interface InputObject {
  key: string;
  value: string;
}

interface IFormValues {
  team_id: null;
  user_id: string;
  spend_date: string;
  spend_date_end: string;
  order_id: string;
  data: any,
  descr: string,
}


const ModalSpend = ({ isOpen }: Props) => {
  const dispatch = useDispatch();

  const [teamId, setTeamId] = useState<number>();

  const item = useSelector((state: any) => state.Spend.item || {});
  const { data: teams, members } = useSelector((state: any) => state.Teams || {});
  const orders = useSelector((state: any) => state.orders.data);

  //const teamsData = useMemo(() => (teams.map((item: any) => ({ value: item.id, label: item.name }))), [teams]);
  const teamsData = teams.map((item: any) => ({ value: item.id, label: item.name }))
  //const ordersData = useMemo(() => (orders.map((item: any) => ({ value: item.id, label: item.name }))), [orders]);
  const ordersData = orders.map((item: any) => ({ value: item.id, label: item.name }));

  /*const memberData = useMemo(() => (
      members.map((item: any) => ({
        value: item.id,
        label:item.name,
      }))
  ), [members]);*/

  const memberData = members.map((item: any) => ({
    value: item.id,
    label:item.name,
  }))

  const itemData = useMemo(() => {
    const dataEntries = Object.entries(item.data || {});
    return dataEntries.map(([key, value]) => ({
      key,
      value,
    }));
  }, [item]);




  /*useEffect(() => {
    console.log("orders", orders)
    debugger
    dispatch(getOrdersByTeam(1));
  }, [orders]);*/

  /*const teamsData = useMemo(() => (teams.map((item: any) => ({ value: item.id, label: item.name }))), [teams]);*/
  /*const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");

  const isTeamLead = authUser.roles.some((role: any) => role.name === "teamlead");
  const userTeamId = isTeamLead ? authUser.team_id : null;

  const filteredOrders = useMemo(() => (isTeamLead ? orders.filter((item: any) => item.team_id === userTeamId) : orders), [orders, isTeamLead, userTeamId]);*/

  /*const orderData = useMemo(() => (
    /!*filteredOrders.map((item: any) => ({
      value: item.id,
      label: item.name,
      team_id: item.team_id,
    }))*!/
      {}
  ), [filteredOrders]);*/

  /*useEffect(() => {
    let effectiveTeamId = item.team_id; 
  
    if (isTeamLead && !effectiveTeamId) {
      effectiveTeamId = userTeamId;
      setIdOrder(userTeamId); 
    } else if (item.team_id) {
      setIdOrder(item.team_id);
    }
    if (effectiveTeamId) {
      dispatch(getTeamMembers(effectiveTeamId));
    }
  }, [item.team_id, isTeamLead, userTeamId, dispatch]);*/

  useEffect(() => {
    if (teamId || item?.order?.team_id) {
      dispatch(getOrdersByTeam(teamId || item?.order?.team_id))
      dispatch(getTeamMembers(teamId || item?.order?.team_id))
    }
  }, [teamId, item])

  useEffect(() => {
    return () => {
      dispatch(clearSpend());
    }
  }, [])

  /*const filteredTeams = useMemo(() => (isTeamLead ? teams.filter((item: any) => item.id === userTeamId) : orders), [orders, isTeamLead, userTeamId]);*/

  /*const memberData = useMemo(() => (
    members.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      ), [members]);

  const itemData = useMemo(() => {
    const dataEntries = Object.entries(item.data || {});
    return dataEntries.map(([key, value]) => ({
      key,
      value,
    }));
  }, [item]);*/

  const dataFormatter = (str:string) => {
      const date = new Date(str);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
  }
  
  const initialValues: IFormValues = {
    user_id: item?.user_id || "",
    order_id: item?.order_id || "",
    spend_date: item?.spend_date || "",
    spend_date_end: item?.spend_date_end || "",
    data: !itemData.length ? [{ key: "", value: "" }] : itemData,
    team_id: item?.order?.team_id || null,
    descr: item?.descr || "",
  };

  const validationSchema = yup.object().shape({
    team_id: yup.number().required('Team is required'),
    user_id: yup.number().required('User is required'),
    spend_date: yup.string().required('Start date is required'),
    spend_date_end: yup.string().required('End date is required'),
    order_id: yup.number().required('Order is required'),
    descr: yup.string().max(191, "Max symbols 191"),
    data: yup.array().of(
        yup.object().shape({
          key: yup.string().required("Key is required"),
          value: yup.number().required("Value is required")
        })
    ).required('At least one spend data is required')
  });
 
  const handleChangeSelect = (name: string, value: any, setFieldValue: any) => {
    setFieldValue(name, value.value)
    if(name === "team_id") {
      setTeamId(value.value)
      setFieldValue("user_id", "")
      setFieldValue("order_id", "")
    }
  };

  const handleSubmitForm = (values: any) => {
    const resultObject: Record<string, string> = values.data.reduce((acc: Record<string, string>, curr: InputObject) => {
      if (curr.key.trim() !== '') {
        acc[curr.key] = curr.value;
      }
      return acc;
    }, {});

    const data = {
      data: resultObject,
      spend_date: dataFormatter(values.spend_date),
      spend_date_end: dataFormatter(values.spend_date_end),
      user_id: values.user_id,
      order_id: values.order_id,
      descr: values.descr
    };


    if (item.id) {
      dispatch(updSpend(data, item.id));
    } else {
      dispatch(addSpend(data));
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={() => dispatch(modalSpend(false))} tag="h4">
        {item?.id ? "Edit" : "Add"} Spends
      </ModalHeader>
      <ModalBody>
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitForm}
            enableReinitialize={true}
            validationSchema={validationSchema}
         >
          {({ values, isValid, dirty, setFieldValue, errors, resetForm, handleChange, handleBlur, touched }) => {
            return (
                <FormikForm>
                  <Row>
                    <div className='mb-3'>
                      <Label>Team: </Label>
                      <Select
                          isSearchable
                          name="team_id"
                          label="Team"
                          placeholder="Team"
                          options={teamsData}
                          // isMulti
                          onChange={(value: any) => handleChangeSelect("team_id", value, setFieldValue)}
                          value={teamsData.find((item: any) => item.value === values.team_id)}
                      />
                    </div>

                    <div className='mb-3'>
                      <Label>Order: </Label>
                      <Select
                          isSearchable
                          name="order_id"
                          label="Order"
                          placeholder="Order"
                          options={ordersData}
                          onChange={(value: any) => handleChangeSelect("order_id", value, setFieldValue)}
                          value={ordersData.find((item: any) => item.value === values.order_id)}

                      />
                    </div>

                    <div className='mb-3'>
                      <Label>Member: </Label>
                      <Select
                          isSearchable
                          name="user_id"
                          label="Member"
                          placeholder="Member"
                          options={memberData}
                          onChange={(value: any) => handleChangeSelect("user_id", value, setFieldValue)}
                          value={memberData.find((item: any) => item.value === values.user_id)}
                      />
                    </div>

                    <Col xs={12}>
                      <Label>Date range: </Label>
                      <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                          }}
                          value={[values.spend_date, values.spend_date_end]}
                          onChange={(date: any) => {
                            setFieldValue('spend_date', date[0]);
                            setFieldValue('spend_date_end', date[1]);
                          }}
                      />
                    </Col>

                    <Col xs={12} className="mt-3">
                    <Label>Description: </Label>
                      <LabelInput
                          label="Description"
                          name="descr"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.descr}
                          hasError={!!(errors.descr && touched.descr)}
                          errorText={errors.descr}
                      />
                    </Col>

                    <Col xs={12}>
                      <AddDynamicInputFields/>
                    </Col>

                  </Row>

                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                            type="submit"
                            className="btn btn-success save-user"
                            disabled={!isValid || !dirty}
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>

                </FormikForm>
            )
          }}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default ModalSpend;
