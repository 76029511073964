import React, {useEffect} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {clearSpends, modalSpend, getSpends } from "../../store/spends/actions";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import TableSpend from "./components/TableSpends";
import NoSearchResult from "../../components/Common/NoSearchResult";
import usePermissions from "src/hooks/usePermissions";
import { getTeamMembers } from "src/store/team/actions";
import ModalSpend from "./components/ModalSpends";

const Spends = () => {
    document.title = "Spends List | Dashboard";
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {hasPermission} = usePermissions();

    const data = useSelector((state: any) => state.Spend.data || []);
    const loading = useSelector((state: any) => state.Spend.loading || false);
    const isOpenModal = useSelector((state: any) => state.Spend.isOpenModal || false);

    let filter = {'per_page': 1000};

    useEffect(() => {
        dispatch(getSpends('', filter))
        return () => {
            dispatch(clearSpends())
        };
    }, []);

    const handleSpendsClicks = () => {
        dispatch(modalSpend(true))
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Dashboard" breadcrumbItem="Spends" />

                <Row className="align-items-center">
                        <Col md={12}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    {
                                        hasPermission("create-spends") &&
                                    <Link to="#" className="btn btn-light" onClick={handleSpendsClicks}>
                                        <i className="bx bx-plus me-1"></i> Add New
                                    </Link>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    { data.length > 0 && <TableSpend data={data} pageSize={10}/> }
                                    { loading ? ( <NoSearchResult /> ) : null }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {isOpenModal && <ModalSpend isOpen={isOpenModal} />}
                </Container>
            </div>
        </React.Fragment>
);
};

export default Spends;