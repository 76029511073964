import { call, put, takeEvery } from "redux-saga/effects"
import { RolesTypes } from "./actionTypes"
import {
  getRolesFailure,
  getRolesSuccess,
  addRolesSuccess,
  addRolesFail,
  updRolesSuccess,
  updRolesFail,
  delRolesSuccess,
  delRolesFail,
  getRoleFailure,
  getRoleSuccess,
  modalRoles
} from "./actions";
import {
  getRoles as getRolesApi,
  getRole as getRoleApi,
  postRole as postRoleApi,
  updateRole as updRoleApi,
  delRole as delRoleApi,
  } from "../../helpers/backend_helper"


function* fetchRoles() {
    try {
      const response: Promise<any> = yield call(getRolesApi);
      yield put(getRolesSuccess(response));
    } catch (error) {
      yield put(getRolesFailure(error));
    }
  }

  function* fetchRole({id}: any ) {
    try {
      const response: Promise<any> = yield call(getRoleApi, id);      
      yield put(getRoleSuccess(response));
    } catch (error) {
      yield put(getRoleFailure(error));
    }
  }

  function* addRoleSaga({data}: any) {
    try {
      const response: Promise<any> = yield call(postRoleApi, data);
      yield put(addRolesSuccess(response));
      yield put(modalRoles(false))
    } catch (error) {
      yield put(addRolesFail(error));
    }
  }

  function* updateRolesSaga({data, id} : any) {
    try{
      const response : Promise<any> = yield call(updRoleApi, data, id);
      yield put(updRolesSuccess(response));
      yield put(modalRoles(false))
    }catch (error) {
      yield put(updRolesFail(error))
    }
  }
  
  function* delRolesSaga({id} : any) {
    try{
      yield call(delRoleApi, id);
      yield put(delRolesSuccess(id));
    }catch (error) {
      yield put(delRolesFail(error))
    }
  }
  

function* rolesSaga() {
    yield takeEvery(RolesTypes.GET_ROLES, fetchRoles)
    yield takeEvery(RolesTypes.GET_ROLE, fetchRole)
    yield takeEvery(RolesTypes.ADD_ROLES, addRoleSaga)
    yield takeEvery(RolesTypes.UPD_ROLES, updateRolesSaga)
    yield takeEvery(RolesTypes.DEL_ROLES, delRolesSaga)

  }

  export default rolesSaga;  