export enum RolesTypes{
    GET_ROLES = "@@roles/GET_ROLES",
    GET_ROLES_SUCCESS = "@@roles/GET_ROLES_SUCCESS",
    GET_ROLES_FAILURE = "@@roles/GET_ROLES_FAILURE",

    GET_ROLE = "@@roles/GET_ROLE",
    GET_ROLE_SUCCESS = "@@roles/GET_ROLE_SUCCESS",
    GET_ROLE_FAILURE = "@@roles/GET_ROLE_FAILURE",

    ADD_ROLES = "@@roles/ADD_ROLES",
    ADD_ROLES_SUCCESS = "@@roles/ADD_ROLES_SUCCESS",

    UPD_ROLES = "@@roles/UPD_ROLES",
    UPD_ROLES_SUCCESS = "@@roles/UPD_ROLES_SUCCESS",
    
    DEL_ROLES = "@@user/DEL_ROLES",
    DEL_ROLES_SUCCESS = "@@roles/DEL_ROLES_SUCCESS",

    ROLES_FAIL = "@@ROLES/ROLES_FAIL",
    ROLES_MODAL = "@@roles/MODAL_USER",
    CLEAR_ROLE = "@@roles/CLEAR_ROLE",
}

export interface RolesState {
    data  : Array<any>;
    loading : boolean;
    error : any;
    isOpenModal: boolean,
    loaded: boolean,
    item: Object
}


