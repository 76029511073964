import {Field, FieldArray, useFormikContext} from "formik";
import {Label, Row} from "reactstrap";
import React from "react";

const AddDynamicInputFields = () => {
    const {setFieldValue, values}: any = useFormikContext();

    const handleAddInput = () => {
        const newData = [...(values?.data as any || {}), {key: "", value: ""}]
        setFieldValue("data", newData)
    };

    const handleDeleteInput = (index: any) => {
        const newData = values?.data.filter((item: any,i: any) => i !== index)
        setFieldValue("data", newData)
    };

    return (
        <div className="mb-3">
            <Label>Spends:</Label>
            <FieldArray
                name="data"
                render={arrayHelpers => (
                    <div>
                        {values.data.map((item:any, index: number) => (

                            <Row key={index} className="mb-3">
                                <div className="hstack gap-3">

                                    <Field name={`data[${index}].key`}  type="text" className="form-control me-auto" />

                                    <Field name={`data[${index}].value`} type="number" className="form-control me-auto"/>

                                    {values.data.length > 1 && (
                                        <button  onClick={() => handleDeleteInput(index)}
                                                 className="btn btn-outline-danger"
                                        >
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    )}
                                    {index === values.data.length - 1 && (
                                        <>
                                            <div className="vr"></div>
                                            <button type="button" className="btn btn-secondary" onClick={handleAddInput}> <i className='fa fa-plus'></i> </button>
                                        </>
                                    )}
                                </div>
                            </Row>
                        ))}
                    </div>
                )}
            />
        </div>
    );
}

export default AddDynamicInputFields;