import React, {useEffect, useMemo, useState} from "react";
import TableContainer from "../../../components/Common/TableContainer";
import {Link} from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import {useDispatch} from "react-redux";
import { delSpend, getSpend } from "src/store/spends/actions";
import usePermissions from "src/hooks/usePermissions";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import JsonViewer from "src/components/Common/JsonViewer";
import {getOrders, getOrdersByTeam, getTeams} from "src/store/actions";

let filter = {'per_page': 15};

const TableSpends = React.memo((props: any) =>  {
    const { data, pageSize, } = props
    const dispatch = useDispatch();
    const {hasPermission} = usePermissions();

    const [deleteModal, setDeleteModal] = useState(false);
    const [contact, setContact] = useState<any>();
    const [showData, setShowData] = useState(false);
    const [json, setJson] = useState<any>();

    const onClickUpdate = (arg: any) => {
        dispatch(getSpend(arg.id))
    };

    const spends = useMemo(() =>
            data.map((item: any) => ({
                id: item.id.toString() || null,
                data: item.data || null,
                spend_data: item.spend_date || null,
                spend_date_end: item.spend_date_end || null,
                user: item?.user?.name || null,
                order: item?.order?.name || "not exhibited",
                description: item.descr || "--",
            })).filter((item: any) => item !== null),
        [data]
    );


    const onClickDelete = (arg: any) => {
        setContact(arg);
        setDeleteModal(true);
    };

    const handleDeleteBroker = () => {
        dispatch(delSpend(contact.id));
        setDeleteModal(false);
    };

    useEffect(() => {
        dispatch(getTeams('', filter))
    }, [data]);

    useEffect(() => {
        //dispatch(getOrders('', filter))
        //dispatch(getOrdersByTeam(1));
    }, []);

    const toggle = () => setShowData(!showData);

    const handleShowData = (data: object) => {
        toggle();
        setJson(data);
    }

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "User",
                accessor: "user",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Order",
                accessor: "order",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Data",
                accessor: "data",
                filterable: true,
                disableFilters: true,
                sortable: true,
                Cell: (cellProps: any) => {
                    let userObj = cellProps.row.original.data
                    return (
                        <Button
                            className={`btn ${userObj ? 'btn-success' : 'btn-secondary'}`}
                            onClick={() => userObj && handleShowData(userObj)}
                            disabled={!userObj}
                        >
                            View
                        </Button>

                    )
                }
            },
            {
                Header: "Description",
                accessor: "description",
                filterable: false,
                disableFilters: true,
                sortable: false,
            },
            {
                Header: "Start data",
                accessor: "spend_data",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "End data",
                accessor: "spend_date_end",
                filterable: true,
                disableFilters: true,
                sortable: true,
            },
            {
                Header: "Action",
                Cell: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-3">
                            {
                            hasPermission("update-spends") &&
                                <Link className="text-success" to="#" onClick={() => {
                                    const spendData = cellProps.row.original;
                                    onClickUpdate(spendData)
                                }}>
                                    <i className="mdi mdi-pencil font-size-18" ></i>
                                </Link>
                            }
                            {
                            hasPermission("delete-spends") &&
                                <Link className="text-danger" to="#">
                                    <i
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={() => {
                                            const spends = cellProps.row.original;
                                            onClickDelete(spends);
                                        }}
                                    ></i>
                                </Link>
                            }
                        </div>
                    );
                },
            },
        ], []);

    return (
        <>
            <TableContainer
                columns={columns}
                data={spends}
                customSort={[{id: 'id', desc: true}]}
                isGlobalFilter={true}
                customPageSize={pageSize}
                className="table align-middle datatable dt-responsive table-check nowrap"
            />

            {deleteModal && <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteBroker}
                onCloseClick={() => setDeleteModal(false)}
            />}
   {showData && <Modal isOpen={showData} toggle={toggle}>
                <ModalHeader toggle={toggle}>Show Data</ModalHeader>
                <ModalBody>
                    <JsonViewer value={json || {}}/>
                </ModalBody>
            </Modal>}
        </>
    )
})
TableSpends.displayName = "TableSpends";
export default TableSpends