import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { ProfileTypes } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

import {
  postJwtProfile,
} from "../../../helpers/backend_helper"

function* editProfile({ payload: { user } } : any) {
  try {

  } catch (error) {
    yield put(profileError(error))
  }
}
export function* watchProfile() {
  yield takeEvery(ProfileTypes.EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
