import {PermissionsTypes, PermissionsState} from "./actionTypes"

export const INIT_STATE : PermissionsState = {
    data: [],
    loading: false,
    error : "",
    isOpenModal: false,
    loaded: false,
    item: {}
  }

 const permissions = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case PermissionsTypes.GET_PERMISSIONS:
        return {
          ...state,
          loading: true
        }
        case PermissionsTypes.GET_PERMISSIONS_SUCCESS:
            return {
              ...state,
              data: action.payload.data,
              loaded: true,
              loading: false
            }
        case PermissionsTypes.GET_PERMISSION_SUCCESS:
              return {
                ...state,
                item: action.payload.data,
              }

        case PermissionsTypes.CLEAR_PERMISSION:
                return {
                  ...state,
                  item: {},
                  error: {},
                }      
        case PermissionsTypes.ADD_PERMISSIONS_SUCCESS:          
                return {
                  ...state,
                  data: [...state.data, action.payload],
                }
        case PermissionsTypes.UPD_PERMISSIONS_SUCCESS:
                  const items = state.data.map((item : any) => {
                    return (item.id === action.payload.id) ? action.payload : item
                  })
                  return {
                    ...state,
                    data: items,
                    error: {},
                    loading: false,
                    loaded: true
                  }  
         case PermissionsTypes.DEL_PERMISSIONS_SUCCESS:
                    return {
                      ...state,
                      data: state.data.filter((item : any) => item.id.toString() !== action.payload.toString() ),
                      error: {},
                      loading: false,
                      loaded: true
                    }
          case PermissionsTypes.PERMISSIONS_FAIL:
                      return {
                        ...state,
                        error: action.payload,
                        loaded: false,
                        loading: false
                      }     

          case PermissionsTypes.PERMISSIONS_MODAL:
                return {
                  ...state,
                  isOpenModal: action.status
                }
      default:
        return state;
    }
  }

  export default permissions;