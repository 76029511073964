import { call, put, takeEvery } from "redux-saga/effects"
import { CheckinTypes } from "./actionsTypes"



import {
    getCheckin,
    getCheckinSuccess,
    clearCheckin,
    getCheckinFail,
    addNewUserForCheckinSuccess,
    modalCheckin
  } from "./actions"


  import {
    getCheckin as getCheckinApi,
    postCheckinUser as  postCheckinUserApi,
  } from "../../helpers/backend_helper";



function* fetchChekin({filter}:any){
  try{
    const response : Promise<any> = yield call(getCheckinApi, filter);
    yield put(getCheckinSuccess(response))
  }catch (error){
    yield put(getCheckinFail(error))
  }
}

function* addUserChekin({data}: any){
  try{
    const response: Promise<any> = yield call(postCheckinUserApi, data)

    // yield put(addNewUserForCheckinSuccess(response));
    yield put(modalCheckin(false))
  } catch (error) {
    console.log(error)
  }
}


function* checkinSaga(){
  yield takeEvery(CheckinTypes.GET_CHECKINS, fetchChekin)
  yield takeEvery(CheckinTypes.ADD_USER_CHECKIN, addUserChekin)
}


export default checkinSaga;