export enum DashboardTypes{
    GET_DASHBOARD = "@@dashboard/GET_DASHBOARD",
    GET_DASHBOARD_SUCCESS = "@@dashboard/GET_DASHBOARD_SUCCESS",
    GET_DASHBOARD_FAILURE = "@@dashboard/GET_DASHBOARD_FAILURE",
}

export interface DashboardState {
    data  : Array<any>;
    loading : boolean;
    error : any;
    loaded: boolean,
}


