import { BrokerTypes } from "./actionsTypes";


export const getBrokers = (next_page: string, filter: object) =>({
    type: BrokerTypes.GET_BROKERS,
    next_page,
    filter
})
export const getBrokersSuccess = (data : any) =>({
    type: BrokerTypes.GET_BROKERS_SUCCESS,
    payload: data,
})

export const getBroker = (id: number) => ({
    type: BrokerTypes.GET_BROKER,
    id
  })

  export const getBrokerSuccess = (data : any) => ({
    type: BrokerTypes.GET_BROKER_SUCCESS,
    payload: data,
  })

export const getBrokerFail = (error : any) => ({
    type: BrokerTypes.BROKER_FAIL,
    payload: error,
})

export const clearBrokers = () => ({
    type: BrokerTypes.CLEAR_BROKERS,
})

export const getBrokersIdSuccess =(data: any)=> ({
      type: BrokerTypes.GET_BROKERS_ID_SUCCESS,
      payload: data,
})

export const getBrokersId =()=> ({
  type: BrokerTypes.GET_BROKERS_ID,
})


export const clearBroker = () => ({
    type: BrokerTypes.CLEAR_BROKER,
})

export const modalBroker = (status: boolean) => ({ 
    type: BrokerTypes.MODAL_BROKER,
    status
})

export const addBroker =(data: any) =>({
    type: BrokerTypes.ADD_BROKER,
    data
})

export const addBrokerSuccess = (data: any) => ({
    type: BrokerTypes.ADD_BROKER_SUCCESS,
    payload: data
  })

  export const addBrokerFail = (error : any) => ({
    type: BrokerTypes.BROKER_FAIL,
    payload: error,
  })


  export const updBroker = (data : any, id: number) => ({
    type: BrokerTypes.UPD_BROKER,
    id, data
  })
  
  export const updBrokerSuccess = (data: any) => ({
    type: BrokerTypes.UPD_BROKER_SUCCESS,
    payload: data
  })
  
  export const updBrokerFail = (error : any) => ({
    type: BrokerTypes.BROKER_FAIL,
    payload: error,
  })


  export const delBroker = (id: number) => ({
    type: BrokerTypes.DEL_BROKER,
    id
  })
  
  export const delBrokerSuccess = (id: number) => ({
    type: BrokerTypes.DEL_BROKER_SUCCESS,
    payload: id
  })
  
  export const delBrokerFail = (error : any) => ({
    type: BrokerTypes.BROKER_FAIL,
    payload: error,
  })