import React, { useEffect, useMemo } from "react";
import { Form, ModalBody, ModalHeader, Modal, Row, Col, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import LabelInput from "../../../components/Common/LabelInput";
import { addRole, clearRole, modalRoles, updRoles } from "src/store/actions";

interface Props {
  isOpen: boolean;
}

interface IFormValues {
  name: string;
  permissions: number[];
}

const ModalRoles: React.FC<Props> = ({ isOpen }: Props) => {
  const dispatch = useDispatch();
  const item = useSelector((state: any) => state.roles.item);
  const permissions = useSelector((state: any) => state.permissions.data || []);

  const validationSchema = yup.object().shape({
    name: yup.string().required("required"),
    permissions: yup.array().min(1, "required"),
  });

  const initialValues: IFormValues = useMemo(() => {
    return {
      name: item.role?.name || "",
      permissions: item?.permissions || [],
    };
  }, [item]);

  const handleSubmitForm = (values: IFormValues) => {
    if (item.role?.id) {
      dispatch(updRoles(values, item.role?.id));
    } else {
      dispatch(addRole(values));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearRole());
    };
  }, [])

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitForm,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const close = () => {
    dispatch(modalRoles(false));
    resetForm();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const id = parseInt(name); // Преобразуем строку в число
    const updatedPermissions = values.permissions.includes(id)
        ? values.permissions.filter(permission => permission !== id)
        : [...values.permissions, id];
    setFieldValue('permissions', updatedPermissions);
  }

  return (
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close} tag="h4">
          {item.role?.id ? "Edit" : "Add"} Role
        </ModalHeader>
        <ModalBody>
          <Form className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className="mb-3">
                  <LabelInput
                      label="Name*"
                      placeholder="Name*"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      hasError={!!(errors.name && touched.name)}
                      errorText={errors.name}
                  />
                </div>
                <div className="mb-3">
                  {permissions.map((perm: any, index: number) => (
                      <div className="form-check" key={perm.id}>
                        <label className="ml-3">
                          <Input
                              className="form-check-input"
                              type="checkbox"
                              name={perm.id.toString()}
                              onChange={handleCheckboxChange}
                              checked={values.permissions.includes(perm.id)}
                          />
                          {perm.name}
                        </label>
                      </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
  );
};

export default ModalRoles;
