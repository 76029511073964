import { BrokerTypes, BrokerState } from './actionsTypes';



export const INIT_STATE: BrokerState = {
    data: [],
    dataBrokerId:[],
    item: {},
    pagination: {},
    error: {},
    loading: false,
    loaded: false,
    isOpenModal: false,
}



const broker = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case BrokerTypes.GET_BROKERS:
            return {
                ...state,
                loading: true
            }
        case BrokerTypes.GET_BROKERS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: action.payload.pagination,
                loaded: true,
                loading: false
            }
        case BrokerTypes.GET_BROKERS_ID_SUCCESS:
                return{
                    ...state,
                    dataBrokerId: action.payload,
                }
        case BrokerTypes.CLEAR_BROKERS: return INIT_STATE;

        case BrokerTypes.ADD_BROKER_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
            }
        case BrokerTypes.DEL_BROKER_SUCCESS:
            return {
                ...state,
                data: state.data.filter((item: any) => item.id.toString() !== action.payload.toString()),
                error: {},
                loading: false,
                loaded: true
            }

        case BrokerTypes.CLEAR_BROKER:
            return {
                ...state,
                item: {},
                error: {},
            }

        case BrokerTypes.GET_BROKER_SUCCESS:
            return {
                ...state,
                item: action.payload,
            }

        case BrokerTypes.UPD_BROKER_SUCCESS:
            const items = state.data.map((item: any) => {
                return (item.id === action.payload.id) ? action.payload : item
            })
            return {
                ...state,
                data: items,
                error: {},
                loading: false,
                loaded: true
            }

        case BrokerTypes.BROKER_FAIL:
            return {
                ...state,
                error: action.payload,
                loaded: false,
                loading: false
            }
        case BrokerTypes.MODAL_BROKER:
            return {
                ...state,
                isOpenModal: action.status
            }
        default:
            return state
    }
}

export default broker