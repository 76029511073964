import {DashboardTypes, DashboardState} from "./actionTypes"

export const INIT_STATE : DashboardState = {
    data: [],
    loading: false,
    error : "",
    loaded: false,
  }

 const dashboard = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case DashboardTypes.GET_DASHBOARD:
        return {
          ...state,
          loading: true
        }
        case DashboardTypes.GET_DASHBOARD_SUCCESS:
            return {
              ...state,
              data: action.payload,
              loaded: true,
              loading: false
            }
      default:
        return state;
    }
  }

  export default dashboard;