export enum BrokerTypes {
    GET_BROKERS = "@@brokers/GET_BROKERS",
    GET_BROKERS_SUCCESS = "@@brokers/GET_BROKERS_SUCCES",
    GET_BROKERS_ID = "@@BROKERS/GET_BROKERS_ID",
    GET_BROKERS_ID_SUCCESS = "@@BROKERS/GET_BROKERS_ID_SUCCESS",
    CLEAR_BROKERS = "@@brokers/CLEAN_BROKERS",
    CLEAR_BROKER = "@@brokers/CLEAR_BROKER",
    GET_BROKER = "@@brokers/GET_BROKER",
    GET_BROKER_SUCCESS = "@@team/GET_BROKER_SUCCESS",
    ADD_BROKER = "@@brokers/ADD_BROKER",
    ADD_BROKER_SUCCESS = "@@brokers/ADD_BROKER_SUCCESS",
    UPD_BROKER = "@@brokers/UPD_BROKER",
    UPD_BROKER_SUCCESS = "@@brokers/UPD_BROKER_SUCCESS",
    DEL_BROKER = "@@brokers/DEL_BROKER",
    DEL_BROKER_SUCCESS = "@@brokers/DEL_BROKER_SUCCESS",

    BROKER_FAIL ="@@broker/BROKER_FAIL",
    MODAL_BROKER = "@@broker/MODAL_BROKER",


}






export interface BrokerState {
    data: Array<any>;
    dataBrokerId: Array<any>;
    item: Object;
    pagination: Object;
    error : Object;
    loading: boolean,
    loaded: boolean,
    isOpenModal: boolean,
}


