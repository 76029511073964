import {JiraState, JiraTypes} from "./actionTypes"

export const INIT_STATE : JiraState = {
  statistics: [],
  projects: [],
  users: [],
  error: {},
  loadingProjects: false,
  loadedProjects: false,
  loadingJiraUsers: false,
  loadedJiraUsers: false,
  loadedJiraStatistics: false,
  loadingJiraStatistics: false,
}

const teams = (state = INIT_STATE, action :any) => {
  switch (action.type) {
    case JiraTypes.GET_PROJECTS:
      return {
        ...state,
        loadingProjects: true
      }

    case JiraTypes.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        loadingProjects: false
      }

    case JiraTypes.CLEAR_PROJECTS:
        return {
          ...state,
          projects: [],
          loadingProjects: false,
          loadedProjects: false
        }

    case JiraTypes.GET_JIRA_USERS:
      return {
        ...state,
        loadingJiraUsers: true
      }

    case JiraTypes.GET_JIRA_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loadingJiraUsers: false,
        loadedJiraUsers: true
      }

    case JiraTypes.CLEAR_JIRA_USERS:
      return {
        ...state,
        users: [],
        loadingJiraUsers: false,
        loadedJiraUsers: false
      }


    case JiraTypes.GET_JIRA_STATISTICS:
      return {
        ...state,
        loadingJiraStatistics: true
      }

    case JiraTypes.GET_JIRA_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload,
        loadingJiraStatistics: false,
        loadedJiraStatistics: true
      }

    case JiraTypes.CLEAR_JIRA_STATISTICS:
      return {
        ...state,
        statistics: [],
        loadingJiraStatistics: false,
        loadedJiraStatistics: false
      }


    case JiraTypes.JIRA_FAIL:
      return {
        ...state,
        error: action.payload,
        loaded: false,
        loading: false
      }

    default:
      return state
  }
}

export default teams
