import { call, put, takeEvery } from "redux-saga/effects"
import { PriceDayTypes } from "./actionsTypes"

import {
    getPricesDaySuccess,
    getPriceDaySuccess,
    getPriceDayFail,
    addPriceDayFail,
    addPriceDaySuccess,
    updPriceDayFail,
    updPriceDaySuccess,
    modalPriceDay,
    delPriceDaySuccess,
    delPriceDayFail
} from "./actions";

import {
    getPricesDay as getPricesDayApi,
    getPriceDay as getPriceDayApi,
    postPriceDay as postPriceDayApi,
    updatePriceDay as updatePriceDayApi,
    delPriceDay as delPriceDayApi
  } from "../../helpers/backend_helper";


  function* fetchPricesDay({next_page, filter}: any) {
    try {
      const response : Promise<any> = yield call(getPricesDayApi, next_page, filter);
      yield put(getPricesDaySuccess(response))
    } catch (error) {
      yield put(getPriceDayFail(error))
    }
  }
  
  function* fetchPriceDay({id}: any ) {
    try {
      const response: Promise<any> = yield call(getPriceDayApi, id);
        if(response) {
            yield put(getPriceDaySuccess(response));
            yield put(modalPriceDay(true))
        }
    } catch (error) {
      yield put(updPriceDayFail(error));
    }
  }

  function* addPriceDaySaga({data}: any) {
    try {
      const response: Promise<any> = yield call(postPriceDayApi, data);
      yield put(addPriceDaySuccess(response));
      yield put(modalPriceDay(false))
    } catch (error) {
      yield put(addPriceDayFail(error));
    }
  }
  
  function* updatePriceDaySaga({data, id} : any) {
    try{
      const response : Promise<any> = yield call(updatePriceDayApi, data, id);
      yield put(updPriceDaySuccess(response));
      yield put(modalPriceDay(false))
    }catch (error) {
      yield put(updPriceDayFail(error))
    }
  }
  
  function* delPriceDaySaga({id} : any) {

    try{
      yield call(delPriceDayApi, id);
      yield put(delPriceDaySuccess(id));
    }catch (error) {
      yield put(delPriceDayFail(error))
    }
  }


function* priceDaySaga() {
    yield takeEvery(PriceDayTypes.GET_PRICES_DAY, fetchPricesDay)
    yield takeEvery(PriceDayTypes.GET_PRICE_DAY, fetchPriceDay)
    yield takeEvery(PriceDayTypes.ADD_PRICE_DAY, addPriceDaySaga)
    yield takeEvery(PriceDayTypes.UPD_PRICE_DAY, updatePriceDaySaga)
    yield takeEvery(PriceDayTypes.DEL_PRICE_DAY, delPriceDaySaga)
  }
  
  export default priceDaySaga;


