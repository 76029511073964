import { StatisticTypes, StatisticState } from './actionsTypes';



export const INIT_STATE: StatisticState = {
    data: [],
    error: {},
    loading: false,
    loaded: false,
}

const statistic = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case StatisticTypes.GET_STATISTIC:
            return {
                ...state,
                loading: true
            }
        case StatisticTypes.GET_STATISTIC_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loaded: true,
                loading: false
            }
        case StatisticTypes.CLEAR_STATISTIC: return INIT_STATE;


        case StatisticTypes.STATISTIC_FAIL:
            return {
              ...state,
              error: action.payload,
              loaded: false,
              loading: false
            }


        default:
            return state
    }
}


export default statistic