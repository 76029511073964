export enum StatisticTypes {
    GET_STATISTIC = "@@statistic/GET_STATISTIC",
    GET_STATISTIC_SUCCESS = "@@statistic/GET_STATISTIC_SUCCESS",
    CLEAR_STATISTIC = "@@statistic/CLEAR_GET_STATISTIC",
    STATISTIC_FAIL = "@@STATISTIC_FAIL",
}

export interface StatisticState {
    data  : Array<any>;
    error: {},
    loading: false,
    loaded: false,
}