import { OrdersTypes } from "./actionsTypes";


export const getOrders = (next_page: string, filter: object) =>({
    type: OrdersTypes.GET_ORDERS,
    next_page,
    filter
})
export const getOdersSuccess = (data : any) =>({
    type: OrdersTypes.GET_ORDERS_SUCCESS,
    payload: data,
})

export const getOrdersByTeam = (id: number) =>({
  type: OrdersTypes.GET_ORDERS_BY_TEAM,
  id,
})
export const getOrdersByTeamSuccess = (data : any) =>({
  type: OrdersTypes.GET_ORDERS_BY_TEAM_SUCCESS,
  payload: data,
})

export const getOrder = (id: number) => ({
  type: OrdersTypes.GET_ORDER,
  id
})

export const getOrdersSuccess = (data : any) => ({
  type: OrdersTypes.GET_ORDER_SUCCESS,
  payload: data,
})


export const addOrders =(data: any) =>({
  type: OrdersTypes.ADD_ORDERS,
  data
})

export const addOrdersSuccess = (data: any) => ({
  type: OrdersTypes.ADD_ORDERS_SUCCESS,
  payload: data
})

export const addOrdersFail = (error : any) => ({
  type: OrdersTypes.ORDERS_FAIL,
  payload: error,
})


export const updOrders = (data : any, id: number) => ({
  type: OrdersTypes.UPD_ORDERS,
  id, data
})

export const updOrdersSuccess = (data: any) => ({
  type: OrdersTypes.UPD_ORDERS_SUCCESS,
  payload: data
})

export const updOrdersFail = (error : any) => ({
  type: OrdersTypes.ORDERS_FAIL,
  payload: error,
})



export const delOrders = (id: number) => ({
  type: OrdersTypes.DEL_ORDERS,
  id
})

export const delOrdersSuccess = (id: number) => ({
  
  type: OrdersTypes.DEL_ORDERS_SUCCESS,
  payload: id
})

export const delOrdersFail = (error : any) => ({
  type: OrdersTypes.ORDERS_FAIL,
  payload: error,
})

export const modalOrders = (status: boolean) => ({ 
  type: OrdersTypes.MODAL_ORDERS,
  status
})

export const clearOrders = () => ({
  type: OrdersTypes.CLEAR_ORDERS,
})
