import React, {useEffect} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import TableUser from "./components/TableTeam";
import NoSearchResult from "../../components/Common/NoSearchResult";
import ModalUser from "./components/Modal";
import {getUsers, modalUser, clearUsers} from "../../store/user/actions";
import usePermissions from "src/hooks/usePermissions";
import { getRoles } from "src/store/actions";

const Users = () => {
    document.title = "User List | Dashboard";
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {hasPermission} = usePermissions();

    const data = useSelector((state: any) => state.Users.data || []);
    const loading = useSelector((state: any) => state.Users.loading || false);
    const isOpenModal = useSelector((state: any) => state.Users.isOpenModal || false);

    let filter = {'per_page': 1000};

    useEffect(() => {
        dispatch(getUsers('', filter))
        dispatch(getRoles())
        return () => {
            dispatch(clearUsers())
        };
    }, []);

    const handleUserClicks = () => {
        dispatch(modalUser(true))
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Users" />

                    <Row className="align-items-center">
                        <Col md={12}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    {
                                    hasPermission("create-users") &&  
                                            <Link to="#" className="btn btn-light" onClick={handleUserClicks}>
                                                <i className="bx bx-plus me-1"></i> Add New
                                            </Link>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    { data.length > 0 && <TableUser data={data} pageSize={20}/> }
                                    { loading ? ( <NoSearchResult /> ) : null }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>

                {isOpenModal && <ModalUser isOpen={isOpenModal} />}
            </div>
        </React.Fragment>
    );
};

export default Users;