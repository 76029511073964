import React from "react";
import { useNavigate } from "react-router-dom";

const RoleBasedAuth = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");

  if (!authUser) {
    navigate("/login");
  }
  if (authUser?.roles <= 0) {
    navigate("/no-access");
    // return null;
  }

  return <>{children}</>;
};

export default RoleBasedAuth;
