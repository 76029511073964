import { call, put, takeEvery } from "redux-saga/effects"
import { JiraTypes } from "./actionTypes"

import {
  getJiraStatisticsSuccess,
  getJiraUsersSuccess,
  getProjectsSuccess,
  jiraFail,
} from "./actions"

import {
  getProjects as getProjectsApi,
  getJiraUsers as getJiraUsersApi,
  getJiraStatistics as getJiraStatisticsApi
} from "../../helpers/backend_helper"

function* fetchProjects() {
  try {
    const response : Promise<any> = yield call(getProjectsApi)
    yield put(getProjectsSuccess(response))
  } catch (error) {
    yield put(jiraFail(error))
  }
}

function* fetchJiraUsers() {
  try {
    const response: Promise<any> = yield call(getJiraUsersApi);
    yield put(getJiraUsersSuccess(response))
  } catch (error) {
    yield put(jiraFail(error))
  }
}

function* fetchJiraStatistics({data}: any) {
  try {
    const response: Promise<any> = yield call(getJiraStatisticsApi, data);
    yield put(getJiraStatisticsSuccess(response))
  } catch (error) {
    yield put(jiraFail(error))
  }
}


function* jiraSaga() {
  yield takeEvery(JiraTypes.GET_PROJECTS, fetchProjects)
  yield takeEvery(JiraTypes.GET_JIRA_USERS, fetchJiraUsers)
  yield takeEvery(JiraTypes.GET_JIRA_STATISTICS, fetchJiraStatistics)
}

export default jiraSaga;